import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useAppSelector } from "@app/store/hooks";
import classNames from "classnames";
import { isObject } from "lodash-es";
import Select, { Styles, OptionTypeBase } from "react-select";
import { Components } from "react-select/src/components";
import CreatableSelect from "react-select/creatable";
import { useTypeaheadSearch } from "@common/hooks/useTypeaheadSearch/useTypeaheadSearch";
import { getTheme } from "@app/store/userPreferences/userPreferences.selector";
import { APP_THEME } from "@common/constants/application.constants";
import { ActionMeta, MenuPlacement, MenuPosition, ValueType } from "react-select/src/types";
import { useSelectComponents } from "./useSelectComponents";
import { getTypeaheadOptions, mergeStyles } from "./select.helpers";
import "./select.less";

export const COLORS = {
    YELLOW: "#FFDA6A",
    BLACK100: "#202020",
    BLACK96: "#292929",
    BLACK92: "#313131",
    WHITE: "#fff",
    BLUE: "#4668bf",
    MID_GREY: "#707070",
    LIGHT_GREY: "#f5f5f5",
};

const SELECT_LIGHT_STYLES = {
    control: styles => ({
        ...styles,
        backgroundColor: "white",
        width: "100%",
        borderBottom: `1px solid ${COLORS.BLUE}`,
        minHeight: "26px",
        maxHeight: "120px",
        ":hover": {
            borderColor: "#0000aa",
        },
    }),
    dropdownIndicator: styles => ({
        ...styles,
        padding: "0 8px",
        color: COLORS.YELLOW,
        ":hover": {
            borderColor: COLORS.YELLOW,
        },
    }),
    menuPortal: styles => ({
        ...styles,
        zIndex: 9999,
    }),
    menu: styles => ({
        ...styles,
        margin: 0,
    }),
    option: styles => ({
        ...styles,
        color: "#ff6e05",
    }),
    multiValueRemove: styles => ({
        ...styles,
        ":hover": {
            color: "white",
        },
    }),
} as Styles<OptionTypeBase, boolean>;

export const SELECT_DARK_STYLES = {
    control: (styles, state) => ({
        ...styles,
        backgroundColor: state.isDisabled ? COLORS.MID_GREY : COLORS.BLACK100,
        border: "none",
        minHeight: "26px",
        maxHeight: state.selectProps.height || "125px",
        overflow: state.selectProps.height ? "hidden" : "auto",
        borderRadius: 0,
        width: "100%",
        borderBottom: `1px solid ${COLORS.WHITE}`,
        boxShadow: "none",
        outline:
            state.isFocused || state.selectProps.invalid
                ? "2px solid rgba(255, 218, 106, 0.6)"
                : 0,
        transition: "none",
        ":hover": {
            borderColor: COLORS.WHITE,
        },
    }),
    dropdownIndicator: (styles, state) => ({
        ...styles,
        padding: state.selectProps.height <= 30 ? "4px" : "8px",
        color: COLORS.YELLOW,
        ":hover": {
            borderColor: COLORS.YELLOW,
        },
    }),
    menuPortal: styles => ({
        ...styles,
        zIndex: 9999,
    }),
    menu: styles => ({
        ...styles,
        margin: 0,
        background: COLORS.BLACK100,
    }),
    input: styles => ({
        ...styles,
        color: COLORS.WHITE,
    }),
    indicatorsContainer: (styles, state) => ({
        ...styles,
        alignItems: "start",
        height: state.selectProps.height,
    }),
    valueContainer: styles => ({
        ...styles,
        padding: "2px 12px",
    }),
    singleValue: (styles, state) => ({
        ...styles,
        color: state.isDisabled ? COLORS.LIGHT_GREY : COLORS.WHITE,
    }),
    multiValueLabel: (styles, state) => ({
        ...styles,
        padding: state.data.hideRemoveButton ? "3px 6px" : "3px",
    }),
    placeholder: (styles, state) => ({
        ...styles,
        color: state.isDisabled ? COLORS.LIGHT_GREY : COLORS.YELLOW,
    }),
    clearIndicator: styles => ({
        ...styles,
        color: COLORS.YELLOW,
    }),
    indicatorSeparator: styles => ({
        ...styles,
        backgroundColor: COLORS.YELLOW,
        width: "2px",
    }),
    menuList: styles => ({
        ...styles,
        background: COLORS.BLACK100,
    }),
    option: (styles, state) => {
        const selected = state.isSelected
            ? {
                  background: COLORS.BLACK100,
                  color: COLORS.YELLOW,
                  ":before": {
                      content: "'•'",
                      marginRight: "6px",
                  },
              }
            : {};
        const focused = state.isFocused
            ? {
                  color: COLORS.BLACK92,
                  background: COLORS.YELLOW,
              }
            : {};

        return {
            ...styles,
            color: COLORS.WHITE,
            ":hover": {
                color: COLORS.BLACK92,
                background: COLORS.YELLOW,
            },
            ...selected,
            ...focused,
        };
    },
    multiValue: styles => ({
        ...styles,
        backgroundColor: COLORS.YELLOW,
        color: COLORS.BLACK100,
    }),
    multiValueRemove: styles => ({
        ...styles,
        ":hover": {
            color: COLORS.WHITE,
        },
    }),
} as Styles<OptionTypeBase, boolean>;

const INITIAL_STATE = {
    inputValue: "",
};

type TRegularOption = {
    label: string;
    value: string;
    [key: string]: unknown;
};

type TProps<TOption extends OptionTypeBase, TIsMulti extends boolean = false> = {
    value: ValueType<TOption, TIsMulti> | string | number;
    options: TOption[];
    onChange: (value: ValueType<TOption, TIsMulti>, actionMeta: ActionMeta<TOption>) => void;
    id?: string;
    testid?: string;
    className?: string;
    placeholder?: string;
    theme?: "dark" | "light";
    required?: boolean;
    invalid?: boolean;
    styles?: object;
    shouldMergeStyles?: boolean;
    customComponents?: Record<string, Components[keyof Components]>;
    menuPosition?: MenuPosition;
    menuPlacement?: MenuPlacement;
    disabled?: boolean;
    disablePortal?: boolean;
    isMulti?: TIsMulti;
    isSearchable?: boolean;
    isOptionsLimited?: boolean;
    isCreatableSelect?: boolean;
    isClearable?: boolean;
    height?: number;
    itemsLimit?: number;
    getOptionLabel?: (option: TOption) => string;
    getOptionValue?: (option: TOption) => any;
    onInputChange?: (input: string) => void;
    formatOptionLabel?: (option: TOption) => ReactNode;
    menuShouldBlockScroll?: boolean;
    hideSelectedOptions?: boolean;
};

export const StlSelect = <TOption extends OptionTypeBase, TIsMulti extends boolean = false>({
    id,
    className,
    theme,
    styles = {},
    shouldMergeStyles = false,
    customComponents,
    options,
    value,
    menuPosition = "absolute",
    menuPlacement = "auto",
    disabled,
    disablePortal,
    isMulti,
    isOptionsLimited,
    isCreatableSelect,
    height,
    itemsLimit,
    getOptionLabel = option => (option as unknown as TRegularOption).label,
    getOptionValue = option => (option as unknown as TRegularOption).value,
    onInputChange = () => {},
    ...restProps
}: TProps<TOption, TIsMulti>): JSX.Element => {
    const appTheme = useAppSelector(getTheme) || APP_THEME.DARK;

    if (!theme) {
        // eslint-disable-next-line no-param-reassign
        theme = appTheme;
    }

    const [inputValue, setInputValue] = useState(INITIAL_STATE.inputValue);

    const _styles = useMemo(() => {
        if (shouldMergeStyles) {
            return mergeStyles(
                theme === "light" ? SELECT_LIGHT_STYLES : SELECT_DARK_STYLES,
                styles,
            );
        } else {
            return {
                ...(theme === "light" ? SELECT_LIGHT_STYLES : SELECT_DARK_STYLES),
                ...styles,
            };
        }
    }, [theme, styles, shouldMergeStyles]);

    const getValue = useCallback(
        _value => {
            if (
                _value !== "" &&
                _value !== undefined &&
                (!isObject(_value) || Array.isArray(_value))
            ) {
                return options.find(option => getOptionValue(option as TOption) === _value);
            }

            return _value;
        },
        [options, getOptionValue],
    );

    const parsedValue = useMemo(() => {
        if (isMulti) {
            return Array.isArray(value) && value.map(getValue);
        }

        return getValue(value);
    }, [value, getValue, isMulti]);

    const components = useSelectComponents(restProps, customComponents);

    const _options = useTypeaheadSearch({
        items: useMemo(
            () =>
                getTypeaheadOptions<TOption, TIsMulti>({
                    options,
                    value: parsedValue,
                    isMulti,
                    isOptionsLimited,
                    getOptionValue,
                }),
            [options, parsedValue, isMulti, isOptionsLimited, getOptionValue],
        ),
        searchToken: inputValue,
        limit: itemsLimit,
        getItemLabel: getOptionLabel,
        isLimited: isOptionsLimited,
    } as any);

    const handleInputChange = (_inputValue: string) => {
        setInputValue(_inputValue);
        onInputChange(_inputValue);
    };

    const SelectComponent: typeof React.Component = isCreatableSelect ? CreatableSelect : Select;

    return (
        <SelectComponent
            options={_options}
            components={components}
            className={classNames("stl-select", theme, className)}
            menuPortalTarget={!disablePortal && document.body}
            menuPosition={menuPosition}
            menuPlacement={menuPlacement}
            isDisabled={disabled}
            height={height}
            value={parsedValue}
            menuShouldBlockScroll
            captureMenuScroll={false}
            styles={_styles}
            inputId={id}
            isMulti={isMulti}
            getOptionLabel={getOptionLabel}
            getOptionValue={getOptionValue}
            onInputChange={handleInputChange}
            {...restProps}
        />
    );
};

StlSelect.displayName = "StlSelect";
