import { createSelector } from "reselect";
import { isEqual } from "lodash-es";
import { arrayIncludes } from "@common/utils/arrayIncludes";
import {
    getIsAllVehiclesOrTruckTravelMode,
    getIsBikeOrPedTravelMode,
    getIsPartialMonthsEnabled,
} from "@common/helpers/analysis";
import { ZONE_GEOM_TYPES } from "@common/constants/zoneSet.constants";
import {
    ANALYSIS_TYPES,
    MODES_OF_TRAVEL,
    ORG_COUNTRIES,
} from "@common/constants/analysis.constants";
import { OSM_ZONE_KINDS, ZONE_KINDS, ZONE_ROLES } from "@common/constants/zoneLibrary.constants";
import {
    OSM_NETWORK_TYPES,
    OSM_SEGMENT_SPLIT_TYPES,
    ROAD_TYPES,
    ZONE_TYPES,
} from "@common/features/zonesManager/state/zonesManager.constants";
import {
    MAX_SANDBOX_ZONE_COUNT,
    OSM_VINTAGE,
} from "@app/store/staticData/state/staticData.constants";
import {
    ALL_VEHICLES_TOMTOM_SUPPORTED_ANALYSES,
    ANALYSIS_CONSTANTS,
    ANALYSIS_TYPES_WITH_ONLY_OSM_SEGMENTS,
    CREATE_ANALYSIS_TYPES,
    DEFAULT_ALL_VEHICLES_BY_WEIGHT_YEAR,
    TRAVEL_MODES,
} from "@app/analysis/state/analysisConfiguration.constants";
import { SWITCH_MODES } from "@app/analysis/zones/chooseZones/state/chooseZones.constants";
import {
    getAnalysisTypeCode,
    getGeneral,
    getIsGMCVDEnabled,
    getIsZAHWLAnalysis,
} from "@app/analysis/state/general/general.selectors";
import {
    getAnalysisCountry,
    getBasics,
    getCalibrationSettings,
    getDirectAnalysesZonesLimits,
    travelModeObjSelector,
} from "@app/analysis/basics/state/basics.selectors";
import {
    getSelectedZones,
    getUiStates,
    getZoneRole,
} from "@app/analysis/zones/chooseZones/state/chooseZones.selectors";
import { getAvailableDataPeriods } from "@app/store/staticData/state/staticData.selectors";
import {
    getAvailableDataYears,
    getConfigParams,
    getIsAugmentedDataAvailable,
    getIsSandbox,
    getIsSuperUser,
    getPartialDataPeriods,
} from "@app/store/currentUser/currentUser.selector";
import {
    getAadtSettings,
    getDataMonthSettings,
    getDataPeriodSettings,
    getIsAvailableSpecificProjectDates,
} from "@app/analysis/timePeriods/state/timePeriods.selectors";
import { getOSMSegmentsFilters } from "@common/features/zonesManager/state/zonesManager.selectors";
import {
    collapseMonthsToRanges,
    enabledForAnalysisType,
    enabledForCountry,
    enabledForHistoricalYear,
    enabledForTravelModeAndCalibrationCombination,
    getAvailablePartialDataPeriods,
    isPartialDataPeriod,
    makeDataPeriodItem,
} from "@app/analysis/state/analysisConfiguration.helpers";
import { getCommercialVehicleSettings } from "@app/analysis/addOns/state/addOns.selectors";

export const getZoneLibraryConfiguration = createSelector(
    getAnalysisTypeCode,
    getAnalysisCountry,
    travelModeObjSelector,
    getAadtSettings,
    getOSMSegmentsFilters,
    (analysisTypeCode, analysisCountry, travelMode, { aadtYear }, { networkType }) => {
        if (analysisTypeCode === CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.code) return null;

        const isOSMSegmentsAnalysisTypes =
            ANALYSIS_TYPES_WITH_ONLY_OSM_SEGMENTS.includes(analysisTypeCode) ||
            CREATE_ANALYSIS_TYPES.AADT.code === analysisTypeCode;
        const isAADT2023Analysis =
            analysisTypeCode === CREATE_ANALYSIS_TYPES.AADT.code && aadtYear === 2023;
        const isNetworkAnalysis =
            ALL_VEHICLES_TOMTOM_SUPPORTED_ANALYSES.includes(analysisTypeCode);
        const standardAreasConfig = ZONE_TYPES.STANDARD_AREAS.zoneKinds.reduce(
            (result, zoneKind) => ({
                ...result,
                [zoneKind.code]:
                    zoneKind.country === analysisCountry && !isOSMSegmentsAnalysisTypes,
            }),
            {},
        );

        const isBusZonesAvailable =
            analysisCountry === ORG_COUNTRIES.US.code &&
            travelMode.isBusZonesSupported &&
            !isOSMSegmentsAnalysisTypes;
        // When Bus travel mode is selected, Transit->Bus should be a default selection in zone library picker
        const defaultZoneType =
            isBusZonesAvailable && travelMode.code === TRAVEL_MODES.BUS.code
                ? {
                      id: ZONE_TYPES.TRANSIT.id,
                      filters: {
                          selectedTransitZoneKind: ZONE_KINDS.BUS.code,
                      },
                  }
                : null;

        const isBikeOrPedTravelMode = travelMode.code
            ? getIsBikeOrPedTravelMode(travelMode.code)
            : false;
        const isBikeOrPedNetworkType = arrayIncludes(
            [OSM_NETWORK_TYPES.BICYCLE.id, OSM_NETWORK_TYPES.PEDESTRIAN.id],
            networkType.id,
        );
        const excludedRoads =
            isAADT2023Analysis || isNetworkAnalysis ? [ROAD_TYPES.SERVICE.id] : [];

        const osmSegments =
            isBikeOrPedTravelMode ||
            isBikeOrPedNetworkType ||
            isAADT2023Analysis ||
            isNetworkAnalysis
                ? { splitTypes: [OSM_SEGMENT_SPLIT_TYPES.RESIDENTIAL], excludedRoads }
                : false;
        return {
            defaultZoneType,
            standardAreas: {
                ...standardAreasConfig,
            },
            transit: {
                [ZONE_KINDS.BUS.code]: isBusZonesAvailable,
                [ZONE_KINDS.RAIL.code]: !isOSMSegmentsAnalysisTypes,
            },
            osmSegments,
        };
    },
);

export const getAccessibleDataPeriodsForCountryAndHistoricalYears = createSelector(
    getAvailableDataYears,
    getAvailableDataPeriods,
    getAnalysisCountry,
    (availableDataYears, availableDataPeriods = [], country) => {
        return availableDataPeriods
            .filter(dataPeriod => enabledForCountry(dataPeriod, country))
            .filter(dataPeriod => enabledForHistoricalYear(dataPeriod, availableDataYears));
    },
);

export const getDefaultDataMonthsByMode = createSelector(
    getAccessibleDataPeriodsForCountryAndHistoricalYears,
    getPartialDataPeriods,
    getCommercialVehicleSettings,
    state => getIsGMCVDEnabled(state),
    (accessibleDataPeriods, partialDataPeriods, { cvMedium }, isGMCVDEnabled) => {
        const result = {};

        result[MODES_OF_TRAVEL.ALL_VEHICLES.code] = accessibleDataPeriods
            .filter(dataPeriod => dataPeriod.is_default_lbsv2)
            .reduce(
                (res, dataPeriod) => [
                    ...res,
                    ...makeDataPeriodItem(dataPeriod, partialDataPeriods),
                ],
                [],
            );

        result[MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code] = result[MODES_OF_TRAVEL.ALL_VEHICLES.code];

        const { filtered2022Months, filtered2024Months } = accessibleDataPeriods.reduce(
            (res, dataPeriod) => {
                // GM CVD months should be included only if appropriate feature flag is enabled
                const isEnabled = dataPeriod.has_gm_metric
                    ? isGMCVDEnabled && dataPeriod.is_default_cvd
                    : dataPeriod.is_default_cvd && dataPeriod.has_cvd_volume;

                if (!isEnabled) {
                    return res;
                }

                if (dataPeriod.year === 2024) {
                    res.filtered2024Months.push(dataPeriod);
                }

                if (dataPeriod.year === 2022) {
                    res.filtered2022Months.push(dataPeriod);
                }

                return res;
            },
            {
                filtered2022Months: [],
                filtered2024Months: [],
            },
        );

        result[MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code] = filtered2024Months.length
            ? filtered2024Months
            : filtered2022Months;

        result[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code] = accessibleDataPeriods.filter(
            dataPeriod => dataPeriod.is_default_tt,
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code] = accessibleDataPeriods.filter(
            dataPeriod => dataPeriod.is_default_tt_api,
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code] = accessibleDataPeriods.filter(
            dataPeriod => dataPeriod.year === DEFAULT_ALL_VEHICLES_BY_WEIGHT_YEAR,
        );
        result[MODES_OF_TRAVEL.TRUCK.code] = accessibleDataPeriods.filter(dataPeriod =>
            cvMedium ? dataPeriod.is_default_gps : dataPeriod.is_default_gps_heavy,
        );
        result[MODES_OF_TRAVEL.BICYCLE.code] = accessibleDataPeriods.filter(
            dataPeriod => dataPeriod.is_default_bike,
        );
        result[MODES_OF_TRAVEL.PEDESTRIAN.code] = accessibleDataPeriods.filter(
            dataPeriod => dataPeriod.is_default_ped,
        );
        result[MODES_OF_TRAVEL.BUS.code] = accessibleDataPeriods.filter(
            dataPeriod => dataPeriod.is_default_bus,
        );
        result[MODES_OF_TRAVEL.RAIL.code] = accessibleDataPeriods.filter(
            dataPeriod => dataPeriod.is_default_rail,
        );

        return result;
    },
);

export const getDefaultDataMonthsForMode = createSelector(
    getDefaultDataMonthsByMode,
    state => travelModeObjSelector(state),
    (defaultDataMonthsByMode, travelMode) => defaultDataMonthsByMode[travelMode.code],
);

export const getAvailableDataMonthsByMode = createSelector(
    getAccessibleDataPeriodsForCountryAndHistoricalYears,
    getPartialDataPeriods,
    getAnalysisTypeCode,
    getCalibrationSettings,
    getIsAugmentedDataAvailable,
    getCommercialVehicleSettings,
    state => getIsGMCVDEnabled(state),
    (
        availableDataPeriods,
        partialDataPeriods = [],
        analysisTypeCode,
        { calibrationCode },
        isAugmentedDataAvailable,
        { commercialVehicleType },
        isGMCVDEnabled,
    ) => {
        const dataPeriodsForAnalysisType = availableDataPeriods.filter(dataPeriod =>
            enabledForAnalysisType(dataPeriod, analysisTypeCode),
        );

        const { cvHeavy, cvMedium } = commercialVehicleType;

        const isOnlyHeavyDuty = cvHeavy && !cvMedium;

        const result = {};

        result[MODES_OF_TRAVEL.ALL_VEHICLES.code] = dataPeriodsForAnalysisType
            .filter(dataPeriod =>
                enabledForTravelModeAndCalibrationCombination({
                    dataPeriod,
                    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES.code,
                    calibrationCode,
                }),
            )
            .reduce(
                (res, dataPeriod) => [
                    ...res,
                    ...makeDataPeriodItem(dataPeriod, partialDataPeriods),
                ],
                [],
            );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code] = result[MODES_OF_TRAVEL.ALL_VEHICLES.code];
        result[MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code] = dataPeriodsForAnalysisType.filter(
            dataPeriod =>
                enabledForTravelModeAndCalibrationCombination({
                    dataPeriod,
                    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code,
                    calibrationCode,
                    isGMCVDEnabled,
                }),
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code] = dataPeriodsForAnalysisType.filter(
            dataPeriod =>
                enabledForTravelModeAndCalibrationCombination({
                    dataPeriod,
                    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code,
                    calibrationCode,
                }),
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code] = dataPeriodsForAnalysisType.filter(
            dataPeriod =>
                enabledForTravelModeAndCalibrationCombination({
                    dataPeriod,
                    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code,
                    calibrationCode,
                }),
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code] = dataPeriodsForAnalysisType.filter(
            dataPeriod =>
                enabledForTravelModeAndCalibrationCombination({
                    dataPeriod,
                    travelModeCode: MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code,
                    calibrationCode,
                }),
        );
        result[MODES_OF_TRAVEL.TRUCK.code] = dataPeriodsForAnalysisType.filter(dataPeriod =>
            enabledForTravelModeAndCalibrationCombination({
                dataPeriod,
                travelModeCode: MODES_OF_TRAVEL.TRUCK.code,
                calibrationCode,
                isOnlyHeavyDuty,
            }),
        );
        result[MODES_OF_TRAVEL.BICYCLE.code] = dataPeriodsForAnalysisType.filter(dataPeriod => {
            const isEnabledForTravelModeAndCalibrationCombination =
                enabledForTravelModeAndCalibrationCombination({
                    dataPeriod,
                    travelModeCode: MODES_OF_TRAVEL.BICYCLE.code,
                    calibrationCode,
                });

            return (
                isEnabledForTravelModeAndCalibrationCombination &&
                (dataPeriod.is_bike_augmented ? isAugmentedDataAvailable : true)
            );
        });
        result[MODES_OF_TRAVEL.PEDESTRIAN.code] = dataPeriodsForAnalysisType.filter(dataPeriod => {
            const isEnabledForTravelModeAndCalibrationCombination =
                enabledForTravelModeAndCalibrationCombination({
                    dataPeriod,
                    travelModeCode: MODES_OF_TRAVEL.PEDESTRIAN.code,
                    calibrationCode,
                });

            return (
                isEnabledForTravelModeAndCalibrationCombination &&
                (dataPeriod.is_ped_augmented ? isAugmentedDataAvailable : true)
            );
        });
        result[MODES_OF_TRAVEL.BUS.code] = dataPeriodsForAnalysisType.filter(dataPeriod =>
            enabledForTravelModeAndCalibrationCombination({
                dataPeriod,
                travelModeCode: MODES_OF_TRAVEL.BUS.code,
                calibrationCode,
            }),
        );
        result[MODES_OF_TRAVEL.RAIL.code] = dataPeriodsForAnalysisType.filter(dataPeriod =>
            enabledForTravelModeAndCalibrationCombination({
                dataPeriod,
                travelModeCode: MODES_OF_TRAVEL.RAIL.code,
                calibrationCode,
            }),
        );

        return result;
    },
);

export const getAvailableDataMonthsForMode = createSelector(
    getAvailableDataMonthsByMode,
    state => travelModeObjSelector(state),
    (availableDataMonthsByMode, travelMode) => availableDataMonthsByMode[travelMode.code],
);

export const getAvailableDataPeriodsByMode = createSelector(
    getAvailableDataMonthsByMode,
    getPartialDataPeriods,
    (availableDataMonths, partialDataPeriods = []) => {
        // Assumes availableDataMonths is ordered by year/month
        const rawAll = availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES.code];
        const rawAllVehiclesTomTom = availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code];
        const rawAllVehiclesTomTomApi =
            availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code];
        const rawAllVehiclesByWeight =
            availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code];
        const rawCVD = availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code];
        const rawTruck = availableDataMonths[MODES_OF_TRAVEL.TRUCK.code];
        const rawBikePed = availableDataMonths[MODES_OF_TRAVEL.BICYCLE.code];
        const rawBus = availableDataMonths[MODES_OF_TRAVEL.BUS.code];
        const rawRail = availableDataMonths[MODES_OF_TRAVEL.RAIL.code];

        const result = {};

        result[MODES_OF_TRAVEL.ALL_VEHICLES.code] = collapseMonthsToRanges(
            rawAll,
            getAvailablePartialDataPeriods(rawAll, partialDataPeriods),
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code] = collapseMonthsToRanges(
            rawAll,
            getAvailablePartialDataPeriods(
                availableDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code],
                partialDataPeriods,
            ),
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code] = collapseMonthsToRanges(rawCVD, []);
        result[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code] = collapseMonthsToRanges(
            rawAllVehiclesTomTom,
            [],
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code] = collapseMonthsToRanges(
            rawAllVehiclesTomTomApi,
            [],
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code] = collapseMonthsToRanges(
            rawAllVehiclesByWeight,
            [],
        );
        result[MODES_OF_TRAVEL.TRUCK.code] = collapseMonthsToRanges(rawTruck, []);
        result[MODES_OF_TRAVEL.BICYCLE.code] = collapseMonthsToRanges(rawBikePed, []);
        result[MODES_OF_TRAVEL.PEDESTRIAN.code] = collapseMonthsToRanges(rawBikePed, []);
        result[MODES_OF_TRAVEL.BUS.code] = collapseMonthsToRanges(rawBus, []);
        result[MODES_OF_TRAVEL.RAIL.code] = collapseMonthsToRanges(rawRail, []);

        return result;
    },
);

export const getAvailableDataPeriodsForMode = createSelector(
    getAvailableDataPeriodsByMode,
    state => travelModeObjSelector(state),
    (availableDataPeriodsByMode, travelMode) => availableDataPeriodsByMode[travelMode.code],
);

export const getKFactorDefaultDataMonths = createSelector(
    getAvailableDataPeriods,
    availableDataPeriods =>
        availableDataPeriods.filter(dataPeriod => dataPeriod.is_default_kfactor),
);

export const getDefaultDataPeriodsByMode = createSelector(
    getDefaultDataMonthsByMode,
    getPartialDataPeriods,
    (defaultDataMonths, partialDataPeriods) => {
        const result = {};

        // Assumes defaultDataMonths is ordered by year/month
        const defaultTruck = defaultDataMonths[MODES_OF_TRAVEL.TRUCK.code];
        const defaultBike = defaultDataMonths[MODES_OF_TRAVEL.BICYCLE.code];
        const defaultPed = defaultDataMonths[MODES_OF_TRAVEL.PEDESTRIAN.code];
        const defaultBus = defaultDataMonths[MODES_OF_TRAVEL.BUS.code];
        const defaultRail = defaultDataMonths[MODES_OF_TRAVEL.RAIL.code];

        result[MODES_OF_TRAVEL.ALL_VEHICLES.code] = collapseMonthsToRanges(
            defaultDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES.code],
            partialDataPeriods,
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code] = collapseMonthsToRanges(
            defaultDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.code],
            partialDataPeriods,
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code] = collapseMonthsToRanges(
            defaultDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_CVD.code],
            [],
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code] = collapseMonthsToRanges(
            defaultDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM.code],
            [],
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code] = collapseMonthsToRanges(
            defaultDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_TOMTOM_API.code],
            [],
        );
        result[MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code] = collapseMonthsToRanges(
            defaultDataMonths[MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.code],
            [],
        );
        result[MODES_OF_TRAVEL.TRUCK.code] = collapseMonthsToRanges(defaultTruck, []);
        result[MODES_OF_TRAVEL.BICYCLE.code] = collapseMonthsToRanges(defaultBike, []);
        result[MODES_OF_TRAVEL.PEDESTRIAN.code] = collapseMonthsToRanges(defaultPed, []);
        result[MODES_OF_TRAVEL.BUS.code] = collapseMonthsToRanges(defaultBus, []);
        result[MODES_OF_TRAVEL.RAIL.code] = collapseMonthsToRanges(defaultRail, []);

        return result;
    },
);

export const getDefaultDataPeriodsForMode = createSelector(
    getDefaultDataPeriodsByMode,
    state => travelModeObjSelector(state),
    (defaultDataPeriodsByMode, travelMode) => defaultDataPeriodsByMode[travelMode.code],
);

export const enablePartialDataMonth = createSelector(
    state => travelModeObjSelector(state),
    getPartialDataPeriods,
    getAvailableDataMonthsByMode,
    (travelMode, partialDataPeriods, dataMonthsByMode) => {
        if (!getIsPartialMonthsEnabled()) {
            return false;
        }

        const dataMonths = dataMonthsByMode[travelMode.code];

        return !!dataMonths.find(dataMonth =>
            isPartialDataPeriod(partialDataPeriods, dataMonth.year, dataMonth.month),
        );
    },
);

// Use only for check available data month when switch from
// any travel mode with 'volume' output type to bus/rail/truck(Canada)
export const getAvailableBusRailTruckDataMonths = createSelector(
    getAccessibleDataPeriodsForCountryAndHistoricalYears,
    getAnalysisTypeCode,
    getCommercialVehicleSettings,

    (availableDataPeriods, analysisTypeCode, { cvMedium }) => {
        const dataPeriodsForAnalysisType = availableDataPeriods.filter(dataPeriod =>
            enabledForAnalysisType(dataPeriod, analysisTypeCode),
        );

        const isOnlyHeavyDuty = !cvMedium;

        const result = {};
        result[MODES_OF_TRAVEL.BUS.code] = dataPeriodsForAnalysisType.filter(dataPeriod =>
            enabledForTravelModeAndCalibrationCombination({
                dataPeriod,
                travelModeCode: MODES_OF_TRAVEL.BUS.code,
            }),
        );
        result[MODES_OF_TRAVEL.RAIL.code] = dataPeriodsForAnalysisType.filter(dataPeriod =>
            enabledForTravelModeAndCalibrationCombination({
                dataPeriod,
                travelModeCode: MODES_OF_TRAVEL.RAIL.code,
            }),
        );
        result[MODES_OF_TRAVEL.TRUCK.code] = dataPeriodsForAnalysisType.filter(dataPeriod =>
            enabledForTravelModeAndCalibrationCombination({
                dataPeriod,
                travelModeCode: MODES_OF_TRAVEL.TRUCK.code,
                isOnlyHeavyDuty,
            }),
        );

        return result;
    },
);

// Use only for check available data periods when switch from
// any travel mode with 'volume' output type to bus/rail/truck(Canada)
export const getAvailableBusRailTruckDataPeriods = createSelector(
    getAvailableBusRailTruckDataMonths,
    availableBusRailTruckDataMonths => {
        const rawTruck = availableBusRailTruckDataMonths[MODES_OF_TRAVEL.TRUCK.code];
        const rawBus = availableBusRailTruckDataMonths[MODES_OF_TRAVEL.BUS.code];
        const rawRail = availableBusRailTruckDataMonths[MODES_OF_TRAVEL.RAIL.code];
        const result = {};

        result[MODES_OF_TRAVEL.TRUCK.code] = collapseMonthsToRanges(rawTruck, []);
        result[MODES_OF_TRAVEL.BUS.code] = collapseMonthsToRanges(rawBus, []);
        result[MODES_OF_TRAVEL.RAIL.code] = collapseMonthsToRanges(rawRail, []);

        return result;
    },
);

export const getIsDefaultAadtYearSelected = createSelector(
    getAadtSettings,
    ({ aadtYear, defaultAADTYear }) => {
        return !aadtYear || aadtYear === defaultAADTYear;
    },
);

export const getIsDefaultDataPeriodsSelected = createSelector(
    getDefaultDataPeriodsForMode,
    getDataPeriodSettings,
    (defaultDataPeriods, { dataPeriods }) => {
        return isEqual(defaultDataPeriods, dataPeriods);
    },
);

export const getIsDefaultDataMonthsSelected = createSelector(
    getDefaultDataMonthsForMode,
    getDataMonthSettings,
    (defaultDataMonths, { dataMonths }) => {
        return isEqual(defaultDataMonths, dataMonths);
    },
);

export const getIsDefaultTimePeriodsSelected = createSelector(
    getIsDefaultDataPeriodsSelected,
    getIsDefaultDataMonthsSelected,
    getIsAvailableSpecificProjectDates,
    getBasics,
    (
        isDefaultDataPeriodsSelected,
        isDefaultDataMonthsSelected,
        isAvailableSpecificProjectDates,
        { travelModeCode },
    ) => {
        if (!isAvailableSpecificProjectDates) return isDefaultDataMonthsSelected;

        const isAllVehiclesOrTruckMode = getIsAllVehiclesOrTruckTravelMode(travelModeCode);
        return isAllVehiclesOrTruckMode
            ? isDefaultDataPeriodsSelected
            : isDefaultDataMonthsSelected;
    },
);

export const getAnalysesZonesLimits = createSelector(
    getIsSandbox,
    getDirectAnalysesZonesLimits,
    (isSandbox, analysesZonesLimits) => {
        if (!isSandbox || !analysesZonesLimits) return analysesZonesLimits;

        const sandboxLimits = {};
        Object.entries(analysesZonesLimits.softLimits).forEach(([analysisName, limit]) => {
            sandboxLimits[analysisName] = Math.min(limit, MAX_SANDBOX_ZONE_COUNT);
        });
        return {
            ...analysesZonesLimits,
            softLimits: sandboxLimits,
        };
    },
);

export const getAnalysisTypeZonesLimit = createSelector(
    getAnalysesZonesLimits,
    getAnalysisTypeCode,
    getIsZAHWLAnalysis,
    (zonesLimits, analysisTypeCode, isZAHWLAnalysis) =>
        isZAHWLAnalysis
            ? zonesLimits?.softLimits?.ZA_HWL
            : zonesLimits?.softLimits?.[analysisTypeCode],
);

export const getAnalysisTypeZonesAbsoluteLimit = createSelector(
    getAnalysesZonesLimits,
    getAnalysisTypeCode,
    getIsZAHWLAnalysis,
    (zonesLimits, analysisTypeCode, isZAHWLAnalysis) =>
        isZAHWLAnalysis
            ? zonesLimits?.hardLimits?.ZA_HWL
            : zonesLimits?.hardLimits?.[analysisTypeCode],
);

export const getZonesLimitValidation = createSelector(
    getSelectedZones,
    getAnalysisTypeZonesLimit,
    getAnalysisTypeZonesAbsoluteLimit,
    getIsSuperUser,
    (selectedZones, zonesLimit, zonesAbsoluteLimit, isSuperUser) => {
        const allSelectedZones = Object.values(selectedZones).flat();

        if (!allSelectedZones.length) {
            return {
                isSuperUser,
                canCreateAnalysis: false,
                zonesAbsoluteLimitExceeded: false,
                zonesLimitExceeded: false,
                message: ANALYSIS_CONSTANTS.noSelectedZonesWarning,
            };
        }

        return {
            isSuperUser,
            canCreateAnalysis: allSelectedZones.length <= zonesAbsoluteLimit,
            zonesAbsoluteLimitExceeded: allSelectedZones.length > zonesAbsoluteLimit,
            zonesLimitExceeded: allSelectedZones.length > zonesLimit,
            message: ANALYSIS_CONSTANTS.exceedZonesLimitWarning,
        };
    },
);

export const getCustomZonesConfiguration = createSelector(
    getUiStates,
    travelModeObjSelector,
    getGeneral,
    getSelectedZones,
    getZoneRole,
    getAnalysisTypeZonesLimit,
    getConfigParams,
    getBasics,
    getAadtSettings,
    (
        { activeTabId },
        travelMode,
        { analysisTypeCode, projectFolder },
        zones,
        selectedRole,
        analysisZonesLimit,
        configParams,
        { measurementUnit },
        aadtSettings,
    ) => {
        const isCalibrationTab = activeTabId === SWITCH_MODES.CALIBRATION_ZONES.id;
        const isBikeOrPedTravelMode = getIsBikeOrPedTravelMode(travelMode.code);
        const aadtYear = aadtSettings.aadtYear || aadtSettings.defaultAADTYear;
        const isOSMSegmentsAnalysisTypes =
            ANALYSIS_TYPES_WITH_ONLY_OSM_SEGMENTS.includes(analysisTypeCode);
        const isAADTAnalysis = analysisTypeCode === CREATE_ANALYSIS_TYPES.AADT.code;
        const isAADT2023Analysis = isAADTAnalysis && aadtYear === 2023;
        const isSegmentAnalysis = analysisTypeCode === CREATE_ANALYSIS_TYPES.SEGMENT.code;
        const isMiddleFilterZoneRole =
            analysisTypeCode === CREATE_ANALYSIS_TYPES.ODMF.code &&
            selectedRole === ZONE_ROLES.MIDDLE_FILTERS.accessor;
        const { shapefile_max_zone_set_chunk_size: maxZoneSetChunkSize } = configParams;
        const selectedZonesCount = Object.values(zones).flat().length;
        const drawZoneType =
            isSegmentAnalysis || isAADTAnalysis ? { drawZoneType: ZONE_GEOM_TYPES.LINE.id } : {};
        const allowedZonesConditions = [];

        // Bike/Ped travel modes don't support OSM Tertiary zones
        if (isBikeOrPedTravelMode) {
            allowedZonesConditions.push({
                property: "zone_kind_id",
                value: [OSM_ZONE_KINDS.OSM_TERTIARY.id],
                exclude: true,
            });
        }
        // Analysis types with only OSM Segments support allow only line zones
        if (isOSMSegmentsAnalysisTypes) {
            allowedZonesConditions.push({
                property: "geom_type",
                value: [ZONE_GEOM_TYPES.LINE.id, "L"],
            });
        }
        // Segment and AADT analysis types, and Middle Filter Zone Role support only USER and OSM zone kinds
        if (
            isSegmentAnalysis ||
            (isAADTAnalysis && !isAADT2023Analysis) ||
            isMiddleFilterZoneRole
        ) {
            allowedZonesConditions.push({
                property: "zone_kind_id",
                value: [ZONE_KINDS.USER.id, ZONE_KINDS.OSM.id].flat(),
            });
        }
        // AADT analysis and Middle Filter Zone Role support only pass-through zones
        if (isAADTAnalysis || isMiddleFilterZoneRole) {
            allowedZonesConditions.push({
                property: "is_pass",
                value: [true],
            });
        }
        // AADT 2023 supports: 1. Custom line zones, 2. OSM segments of Residential split type
        // which are not "service" roads
        if (isAADT2023Analysis) {
            allowedZonesConditions.push({
                property: "zone_kind_id",
                value: [ZONE_KINDS.USER.id, OSM_ZONE_KINDS.OSM.id].flat(),
            });
            allowedZonesConditions.push({
                property: "geom_type",
                value: [ZONE_GEOM_TYPES.LINE.id, "L"],
            });
            allowedZonesConditions.push({
                property: "highway",
                value: ROAD_TYPES.SERVICE.roadIds,
                exclude: true,
            });
        }

        // Network Performance Spot config
        if (analysisTypeCode === ANALYSIS_TYPES.NETWORK_PERFORMANCE_SPOT.id) {
            return {
                upload: false,
                draw: false,
                reuse: {
                    modalTitle: "Reuse Gates",
                    excludedZones: zones[selectedRole] || [],
                    isMultiConditions: true,
                    allowedZonesConditions: [
                        [
                            {
                                property: "geom_type",
                                value: [ZONE_GEOM_TYPES.POLYGON.id, "P"],
                            },
                            {
                                property: "zone_kind_id",
                                value: [ZONE_KINDS.GATE.id[0]],
                                additionalProperty: "display_zone_kind_id",
                            },
                        ],
                        [
                            {
                                property: "geom_type",
                                value: [ZONE_GEOM_TYPES.LINE.id, "L"],
                            },
                            {
                                property: "zone_kind_id",
                                additionalProperty: "display_zone_kind_id",
                                value: [ZONE_KINDS.USER.id[0], ...ZONE_KINDS.OSM.id],
                            },
                        ],
                    ],
                    projectFolder,
                },
            };
        }

        // Network Performance Segment/Network OD analyses doesn't allow to draw/upload zones
        if (
            [
                CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.code,
                CREATE_ANALYSIS_TYPES.NETWORK_OD.code,
            ].includes(analysisTypeCode)
        ) {
            return {
                upload: false,
                draw: false,
                reuse: {
                    allowedAnalysisTypes: [
                        CREATE_ANALYSIS_TYPES.NETWORK_PERFORMANCE_SEGMENT.code,
                        CREATE_ANALYSIS_TYPES.NETWORK_OD.code,
                    ],
                    excludedZones: zones[selectedRole] || [],
                    allowedZonesConditions: [
                        {
                            property: "geom_type",
                            value: [ZONE_GEOM_TYPES.LINE.id, "L"],
                        },
                        {
                            property: "zone_kind_id",
                            value: [OSM_ZONE_KINDS.OSM.id],
                            additionalProperty: "display_zone_kind_id",
                        },
                        {
                            property: "zone_vintage",
                            value: OSM_VINTAGE,
                        },
                        {
                            property: "highway",
                            value: ROAD_TYPES.SERVICE.roadIds,
                            exclude: true,
                        },
                    ],
                    projectFolder,
                },
            };
        }

        return {
            draw: {
                ...(isCalibrationTab
                    ? { calibrationTravelMode: travelMode, measurementUnits: [measurementUnit] }
                    : { measurementUnits: [measurementUnit] }),
                ...drawZoneType,
            },
            reuse: {
                excludedZones: zones[selectedRole] || [],
                allowedZonesConditions,
                projectFolder,
            },
            upload: {
                zonesLimit: Math.min(maxZoneSetChunkSize, analysisZonesLimit - selectedZonesCount),
            },
        };
    },
);
