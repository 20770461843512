import { batch } from "react-redux";
import { AnalysesApiService } from "@common/services/server/analysesApi.service";
import { ZonesApiService } from "@common/services/server/zonesApi.service";
import {
    setDefaultAADTYear,
    setDefaultDataPeriodsAndMonths,
} from "@app/analysis/timePeriods/state/timePeriods.actions";
import { getCopyAnalysisMode } from "@app/analysis/state/general/general.selectors";
import { getDraftState } from "@app/analysis/draftAnalysis/state/draftAnalysis.selectors";
import {
    getDefaultDataMonthsForMode,
    getDefaultDataPeriodsForMode,
} from "@app/analysis/state/analysisConfiguration.selectors";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import { extractAnalysesMetadataAndFeatureCodeNameRelation } from "./staticData.helpers";
import { actions } from "./staticData.reducer";

export const {
    setAvailableDataPeriods,
    setSATCAvailableDataPeriods,
    setZoneVintageDates,
    setOSMLayersVintageDate,
    setAnalysisTypesKeywords,
    setAnalysesMetadata,
} = actions;

export const fetchAvailableDataPeriods =
    (config?: { skipTimePeriodsReset: boolean }) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        return AnalysesApiService.getAvailableDataPeriods().then(res => {
            const availableDataPeriods = res.data_periods || [];

            batch(() => {
                dispatch(setAvailableDataPeriods(availableDataPeriods));
                dispatch(setDefaultAADTYear());
            });

            const state = getState();
            const isCopyAnalysisMode = getCopyAnalysisMode(state);
            const draft = getDraftState(state);

            // Do not set default data periods when it is copy or finish draft or 'skipTimePeriodsReset' flag is set.
            if (!isCopyAnalysisMode && !draft.isDraft && !config?.skipTimePeriodsReset) {
                const dataPeriods = getDefaultDataPeriodsForMode(state);
                const dataMonths = getDefaultDataMonthsForMode(state);

                dispatch(setDefaultDataPeriodsAndMonths(dataPeriods, dataMonths));
            }
        });
    };

export const fetchSATCAvailableDataPeriods = () => (dispatch: TAppDispatch) => {
    return AnalysesApiService.getSATCAvailableDataPeriods().then(res => {
        const availableDataPeriods = res.data_months || [];

        dispatch(setSATCAvailableDataPeriods(availableDataPeriods));
    });
};

export const fetchZoneVintageDates = () => (dispatch: TAppDispatch) => {
    return ZonesApiService.getZoneVintageDates().then(res => {
        dispatch(setZoneVintageDates(res.zone_vintage));
    });
};

export const fetchAnalysesTypeKeywords = () => (dispatch: TAppDispatch, getState: TGetState) => {
    return AnalysesApiService.getAnalysesSearchFilters().then(({ data }) => {
        const state = getState();

        const { keywords, mode_of_travel } = data;

        const { modeOfTravelAnalysesData } = extractAnalysesMetadataAndFeatureCodeNameRelation(
            mode_of_travel,
            state,
        );

        batch(() => {
            dispatch(setAnalysesMetadata(modeOfTravelAnalysesData));
            dispatch(setAnalysisTypesKeywords(keywords));
        });
    });
};
