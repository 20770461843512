import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type {
    IAvailableDataPeriodAPI,
    ISATCAvailableDataPeriodAPI,
} from "@common/services/server/analysesApi.types";
import {
    TAnalysesFilterData,
    IKeywordsStringByAnalysisId,
    IOSMVintageDate,
    IVintageDatesByYear,
} from "@app/store/staticData/state/staticData.types";
import { STATIC_DATA_STATE } from "./staticData.state";

export const { actions, reducer } = createSlice({
    name: "staticData",
    initialState: STATIC_DATA_STATE,
    reducers: {
        setAvailableDataPeriods: (
            state,
            action: PayloadAction<Array<IAvailableDataPeriodAPI>>,
        ) => {
            state.availableDataPeriods = action.payload;
        },
        setSATCAvailableDataPeriods: (
            state,
            action: PayloadAction<Array<ISATCAvailableDataPeriodAPI>>,
        ) => {
            state.satcAvailableDataPeriods = action.payload;
        },
        setZoneVintageDates: (state, action: PayloadAction<IVintageDatesByYear>) => {
            state.zoneVintageDates = action.payload;
        },
        setOSMLayersVintageDate: (state, action: PayloadAction<IOSMVintageDate>) => {
            state.osmLayersVintageDate = action.payload;
        },
        setAnalysisTypesKeywords: (state, action: PayloadAction<IKeywordsStringByAnalysisId>) => {
            state.analysisTypesKeywords = action.payload;
        },
        setAnalysesMetadata: (state, action: PayloadAction<TAnalysesFilterData>) => {
            state.analysesMetadata = action.payload;
        },
        resetStaticDataState: () => {
            return STATIC_DATA_STATE;
        },
    },
});

export const staticDataReducer = reducer;
