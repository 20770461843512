import { batch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { HttpService } from "@common/services/server/http.service";
import { AnalysesApiService } from "@common/services/server/analysesApi.service";
import { getBasics } from "@app/analysis/basics/state/basics.selectors";
import { ZonesApiService } from "@common/services/server/zonesApi.service";
import * as generalActions from "@app/analysis/state/general/general.actions";
import {
    validateIntersectionName,
    validateGate,
    convertIntersectionApiObjectToIntersection,
} from "@common/features/intersections/intersection.helpers";
import { getIsTMCAnalysis } from "@common/helpers/analysis";
import { createValidateField } from "@app/analysis/validation";
import { setZonesValidation } from "@app/analysis/zones/chooseZones/state/chooseZones.actions";
import type { Feature, LineString } from "geojson";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import {
    IAnalysis,
    IAnalysisIntersectionZone,
    IValidateIntersectionsData,
} from "@common/services/server/analysesApi.types";
import type { ILineZoneAPI } from "@common/services/server/zonesApi.types";
import type {
    IAvailableIntersection,
    IIntersection,
    IIntersectionZoneGate,
    IHoveredGate,
} from "@common/features/intersections/intersection.types";
import { ZONE_KINDS } from "@common/constants/zoneLibrary.constants";
import { CHOOSE_ZONES_INITIAL_STATE } from "@app/analysis/zones/chooseZones/state/chooseZones.state";
import {
    getGateRoadName,
    getIntersectionZonesFromAnalysis,
    getIsReusedIntersection,
    transformGatesForValidation,
} from "./tmcChooseZones.helpers";
import {
    getEditableFeature,
    getSelectedIntersection,
    getHoveredGate,
    getIntersectionZonesList,
    getApiIntersectionZones,
} from "./tmcChooseZones.selectors";
import { MAP_MODES, NO_SELECTED_INTERSECTION_TEXT } from "./tmcChooseZones.constants";
import { actions } from "./tmcChooseZones.reducer";

export const {
    setMapMode,
    setEditableFeature,
    setIntersectionZones,
    updateIntersectionZone,
    updateAvailableIntersections,
    setHoveredGate: _setHoveredGate,
    resetTMCChooseZonesState: resetTMCChooseZonesReducer,
} = actions;

export const setTMCZonesTabValidation =
    (intersectionName: string, gates: Array<IIntersectionZoneGate>) =>
    (dispatch: TAppDispatch) => {
        const hasEmptyName = !intersectionName.trim().length;
        const hasGateErrors = gates.some(gate => gate.errors?.length);
        const validationResult = [
            ...(hasEmptyName ? ["Please name the intersection."] : []),
            ...(hasGateErrors ? ["Gates data are incomplete"] : []),
        ].join(". ");

        dispatch(
            setZonesValidation({
                ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
                chooseZones: createValidateField(validationResult),
            }),
        );
    };

export const setSelectedGate =
    (selectedGate: IIntersectionZoneGate | null) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const selectedIntersection = getSelectedIntersection(state);

        const intersection = {
            ...selectedIntersection,
            selectedGate,
        } as IIntersection;

        dispatch(updateIntersectionZone(intersection));
    };

export const editSelectedGate = (feature: Feature<LineString>) => (dispatch: TAppDispatch) => {
    batch(() => {
        dispatch(setMapMode(MAP_MODES.EDIT_GATE));
        dispatch(setEditableFeature(feature));
    });
};

export const saveSelectedGate =
    (selectedGate?: IIntersectionZoneGate | null) =>
    (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const editableFeature = getEditableFeature(state);
        const intersection = getSelectedIntersection(state);

        if (!selectedGate) return;

        let _selectedGate: IIntersectionZoneGate;
        const newGates = intersection!.gates.map(_gate => {
            if (_gate.id !== selectedGate.id) return _gate;

            _selectedGate = editableFeature
                ? {
                      ..._gate,
                      line_geom: JSON.stringify(editableFeature?.geometry),
                  }
                : selectedGate;

            return { ..._selectedGate, errors: [] };
        });

        const intersectionZone = {
            ...intersection,
            selectedGate: _selectedGate!,
            gates: newGates,
        } as IIntersection;

        batch(() => {
            dispatch(updateIntersectionZone(intersectionZone));
            _selectedGate?.line_geom && dispatch(validateDrawnGates(_selectedGate.id));
            dispatch(setEditableFeature(null));
        });
    };

export const validateDrawnGates =
    (selectedGateId?: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const { travelModeCode } = getBasics(state);
        const zones = getApiIntersectionZones(state).map(zone => ({
            ...zone,
            gates: transformGatesForValidation(zone.gates),
        })) as unknown as Array<IAnalysisIntersectionZone>;

        const options: IValidateIntersectionsData = {
            zones,
            travel_mode_type: travelModeCode,
        };

        return AnalysesApiService.validateIntersections(options)
            .then(({ data }) => {
                const intersectionZones = getIntersectionZonesList(getState());

                if (!data.length) {
                    intersectionZones.forEach(intersection => {
                        let clearGeometryWarnings = false;
                        const _gates = intersection.gates.map(gate => {
                            if (gate.id !== selectedGateId) {
                                return gate;
                            }

                            if (gate.geometryWarnings?.length) {
                                clearGeometryWarnings = true;
                            }
                            return {
                                ...gate,
                                geometryWarnings: [],
                                errors: [
                                    ...(gate.invalidRole || []),
                                    ...(gate.invalidName || []),
                                    ...(gate.invalidGeometry || []),
                                ],
                            };
                        });

                        if (clearGeometryWarnings) {
                            dispatch(updateIntersectionZone({ ...intersection, gates: _gates }));
                        }
                    });

                    return undefined;
                }

                const validatedIntersections = intersectionZones.map(intersection => {
                    const _gates = intersection.gates.map(gate => {
                        if (gate.id !== selectedGateId) {
                            return gate;
                        }

                        const invalidGate = data.find(
                            ({ gate_name }) => getGateRoadName(gate) === gate_name,
                        );

                        const _gate = validateGate({ gate, gates: intersection.gates });
                        _gate.errors = [
                            ..._gate.invalidRole,
                            ..._gate.invalidName,
                            ..._gate.invalidGeometry,
                        ];

                        if (invalidGate) {
                            _gate.errors.push(...invalidGate.warning_msgs);
                            _gate.geometryWarnings = invalidGate.warning_msgs;
                        } else {
                            _gate.geometryWarnings = [];
                        }

                        return _gate;
                    });

                    return { ...intersection, gates: _gates };
                });

                const newZonesValidation = {
                    ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
                    chooseZones: { isInvalid: true, touched: true, reasons: [] },
                };

                batch(() => {
                    validatedIntersections.forEach(intersection =>
                        dispatch(updateIntersectionZone(intersection)),
                    );
                    dispatch(setZonesValidation(newZonesValidation));
                });

                return validatedIntersections;
            })
            .catch(error => {
                // handled the same way as in saveAnalysis()
                const message = error?.response?.data?.message || error.message;
                dispatch(generalActions.setAnalysisActionError(message));
            });
    };

export const validateImportedIntersection =
    () => (dispatch: TAppDispatch, getState: TGetState) => {
        const intersectionZones = getIntersectionZonesList(getState());
        const validationResult = !intersectionZones.length ? NO_SELECTED_INTERSECTION_TEXT : "";
        const newZonesValidation = {
            ...CHOOSE_ZONES_INITIAL_STATE.validation.fields,
            chooseZones: createValidateField(validationResult),
        };
        dispatch(setZonesValidation(newZonesValidation));
    };

export const setValidatedIntersections =
    (validationResult: string) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();
        const intersectionZones = getIntersectionZonesList(state);

        if (!validationResult || !intersectionZones.length) return;

        const validatedIntersections = intersectionZones.map(intersection => {
            const _intersection = validateIntersectionName(intersection);

            const _gates = _intersection.gates.map((gate, idx, gates) => {
                return validateGate({ gate, gates });
            });

            return { ..._intersection, gates: _gates };
        });

        dispatch(
            setIntersectionZones({ [validatedIntersections[0].id]: validatedIntersections[0] }),
        );
    };

export const setHoveredGate =
    (gate: IHoveredGate | null) => (dispatch: TAppDispatch, getState: TGetState) => {
        const hoveredGate = getHoveredGate(getState());

        if (gate?.zone_id === hoveredGate?.zone_id) return null;

        return dispatch(_setHoveredGate(gate));
    };

export const setImportedIntersection =
    (intersectionZone: IAnalysisIntersectionZone) => (dispatch: TAppDispatch) => {
        const zone = convertIntersectionApiObjectToIntersection(intersectionZone);

        batch(() => {
            dispatch(setIntersectionZones({ [zone.zone_id!]: zone }));
            dispatch(validateImportedIntersection());
        });
    };

export const fetchAvailableIntersectionZones = () => (dispatch: TAppDispatch) => {
    return ZonesApiService.getZonesByZoneKindId(ZONE_KINDS.INTERSECTION.id[0])
        .then(({ zones }) => {
            // 'set_id' and 'set_name' properties are required for ZoneSetsPickerModal
            const _zones = zones.map(zone => ({
                ...zone,
                set_id: zone.zone_id,
                set_name: zone.zone_name,
            })) as Array<IAvailableIntersection>;
            dispatch(updateAvailableIntersections(_zones));
        })
        .catch(HttpService.silentError);
};

export const fetchIntersectionZoneById = (zoneId: number) => (dispatch: TAppDispatch) => {
    return ZonesApiService.getZoneById(ZONE_KINDS.INTERSECTION.id[0], zoneId)
        .then(({ data }) => {
            const zone = {
                ...data,
                id: String(data.zone_id),
                gates: (data as ILineZoneAPI).gates!.map(gate => ({
                    ...gate,
                    id: uuidv4(),
                })) as Array<IIntersectionZoneGate>,
                isImported: true,
            } as IIntersection;

            batch(() => {
                dispatch(setIntersectionZones({ [zone.zone_id!]: zone }));
                dispatch(validateImportedIntersection());
            });
        })
        .catch(HttpService.silentError);
};

export const setTMCInitialData = (analysisData: IAnalysis) => (dispatch: TAppDispatch) => {
    const { project_type: analysisType, intersection_zones: intersectionZones } = analysisData;
    if (!getIsTMCAnalysis(analysisType)) return;

    const hasReusedIntersections =
        intersectionZones.length && intersectionZones.every(zone => getIsReusedIntersection(zone));
    if (hasReusedIntersections) {
        const intersectionZoneId = intersectionZones[0].zone_id;
        dispatch(fetchIntersectionZoneById(intersectionZoneId));
    } else {
        const _intersectionZones = getIntersectionZonesFromAnalysis(intersectionZones);
        dispatch(setIntersectionZones(_intersectionZones));
    }
};
