import moment from "moment";
import { IDateRange } from "@common/components/dateRangeFilter/dateRangeFilter";
import { FuseResult, RangeTuple } from "fuse.js";

export const getYearsArray = ({ startDate, endDate }: IDateRange) => {
    const startYear = moment(startDate).year();
    const endYear = moment(endDate).year();
    const years = [];

    for (let year = startYear; year <= endYear; year++) {
        years.push(year.toString());
    }

    return years;
};

const splitAndHighlight = (
    text: string,
    matches: readonly RangeTuple[],
    className: string,
): React.ReactNode => {
    const parts = [];
    let lastIndex = 0;

    matches.forEach(([start, end]) => {
        if (lastIndex < start) {
            parts.push(text.slice(lastIndex, start));
        }

        parts.push(
            <span key={start} className={className}>
                {text.slice(start, end + 1)}
            </span>,
        );
        lastIndex = end + 1;
    });

    if (lastIndex < text.length) {
        parts.push(text.slice(lastIndex));
    }

    return parts;
};

export const getHighlightedMatchedAnalyses = <
    T extends {
        [key: string]: any;
    },
>(
    matchedAnalysesFuseResult: FuseResult<T>[],
    keys: (keyof T)[],
    className: string,
): T[] => {
    return matchedAnalysesFuseResult.map(({ item, matches }) => {
        if (!matches) {
            return item;
        }

        const matchIndicesArray = keys.map(key => matches!.find(match => match.key === key));

        const highlightedProps = matchIndicesArray.reduce((res, match) => {
            if (match) {
                const key = match.key as keyof T;
                res[key] = splitAndHighlight(item[key] as string, match.indices, className);
            }
            return res;
        }, {} as { [Property in keyof T]?: React.ReactNode });

        return {
            ...item,
            ...highlightedProps,
        };
    });
};

export const formExactMatchFilterExpression = (data: string[] | number[] | null, path: string) =>
    data
        ? data.map(value => ({
              $path: path,
              $val: `'"${value}"`,
          }))
        : [];

export const getAadtFeatureNameByYear = (year: number) => `Estimated_${year}_AADT`;
