import { OSM_VINTAGE } from "@app/store/staticData/state/staticData.constants";
import {
    TAnalysesFilterData,
    IKeywordsStringByAnalysisId,
    IOSMVintageDate,
    IVintageDatesByYear,
} from "@app/store/staticData/state/staticData.types";
import {
    IAvailableDataPeriodAPI,
    ISATCAvailableDataPeriodAPI,
} from "@common/services/server/analysesApi.types";

export interface IStaticDataState {
    osmLayersVintageDate: IOSMVintageDate;
    zoneVintageDates: IVintageDatesByYear;
    analysisTypesKeywords: IKeywordsStringByAnalysisId;
    availableDataPeriods: Array<IAvailableDataPeriodAPI>;
    satcAvailableDataPeriods: Array<ISATCAvailableDataPeriodAPI>;
    analysesMetadata: TAnalysesFilterData;
}

export const STATIC_DATA_STATE = {
    availableDataPeriods: [],
    satcAvailableDataPeriods: [],
    zoneVintageDates: {},
    osmLayersVintageDate: OSM_VINTAGE,
    analysisTypesKeywords: {},
    analysesMetadata: {},
} as IStaticDataState;
