import { BASICS_INITIAL_STATE } from "@app/analysis/basics/state/basics.state";
import { BASICS_ACTION_TYPES } from "@app/analysis/basics/state/basics.actionTypes";

const isFieldsInvalid = validationFields =>
    Object.values(validationFields).some(field => field && field.isInvalid);

export const basicsReducer = (state = BASICS_INITIAL_STATE, { type, data }) => {
    switch (type) {
        case BASICS_ACTION_TYPES.SET_NAME: {
            return {
                ...state,
                name: data,
            };
        }

        case BASICS_ACTION_TYPES.SET_DESCRIPTION: {
            return {
                ...state,
                description: data.description,
            };
        }

        case BASICS_ACTION_TYPES.SET_TAGS: {
            return {
                ...state,
                tagIds: data,
            };
        }

        case BASICS_ACTION_TYPES.SET_MEASUREMENT_UNIT: {
            return {
                ...state,
                measurementUnit: data.measurementUnit,
            };
        }

        case BASICS_ACTION_TYPES.SET_COUNTRY: {
            return {
                ...state,
                country: data.country,
            };
        }

        case BASICS_ACTION_TYPES.UPDATE_ANALYSIS_DATA: {
            return {
                ...state,
                ...data,
            };
        }

        case BASICS_ACTION_TYPES.SET_TRAVEL_MODE_CODE: {
            return {
                ...state,
                travelModeCode: data.travelModeCode,
            };
        }

        case BASICS_ACTION_TYPES.SET_CALIBRATION_CODE: {
            return {
                ...state,
                calibrationSettings: {
                    ...state.calibrationSettings,
                    calibrationCode: data.calibrationCode,
                },
            };
        }

        case BASICS_ACTION_TYPES.SET_AVAILABLE_CALIBRATION_CODES: {
            return {
                ...state,
                calibrationSettings: {
                    ...state.calibrationSettings,
                    availableCalibrationCodes: data.availableCalibrationCodes,
                },
            };
        }

        case BASICS_ACTION_TYPES.SET_AADT_CALIBRATION_YEAR: {
            return {
                ...state,
                calibrationSettings: {
                    ...state.calibrationSettings,
                    aadtCalibrationYear: data.aadtCalibrationYear,
                },
            };
        }

        case BASICS_ACTION_TYPES.SET_VALIDATION: {
            const newFields = {
                ...state.validation.fields,
                ...data.validation,
            };

            return {
                ...state,
                validation: {
                    fields: newFields,
                    isInvalid: isFieldsInvalid(newFields),
                },
            };
        }

        case BASICS_ACTION_TYPES.HIDE_VALIDATION_ALERT: {
            return {
                ...state,
                validation: BASICS_INITIAL_STATE.validation,
            };
        }

        case BASICS_ACTION_TYPES.RESET_REDUCER: {
            return BASICS_INITIAL_STATE;
        }

        case BASICS_ACTION_TYPES.SET_INITIAL_DATA: {
            return {
                ...state,
                ...data,
                calibrationSettings: {
                    ...state.calibrationSettings,
                    ...data.calibrationSettings,
                },
            };
        }

        case BASICS_ACTION_TYPES.SET_ANALYSES_ZONES_LIMITS:
            return {
                ...state,
                analysesZonesLimits: data.analysesZonesLimits,
            };

        default:
            return state;
    }
};
