import { useEffect, useState, MutableRefObject } from "react";
import classNames from "classnames";
import { CSSTransition } from "react-transition-group";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle, faExclamationTriangle, faTimes } from "@fortawesome/pro-solid-svg-icons";
import { useValueRef } from "@common/hooks/useValueRef";
import { StlButton } from "@common/components/button/button";
import type { IHideTimeout, ISnackbar } from "@common/components/snackbar/snackbar.types";
import "./snackbarComponent.less";

const INITIAL_STATE = {
    isMounted: false,
    hideTimeout: {
        id: null,
        start: null,
        remaining: null,
    },
};

const SNACKBAR_TRANSITION_CLASSES = {
    top: {
        enter: "snackbar-animation",
        enterActive: "fadeInDown",
        exit: "snackbar-animation",
        exitActive: "fadeOutUp",
    },
    "top-right": {
        enter: "snackbar-animation",
        enterActive: "fadeInRight",
        exit: "snackbar-animation",
        exitActive: "fadeOutRight",
    },
    "bottom-right": {
        enter: "snackbar-animation",
        enterActive: "fadeInRight",
        exit: "snackbar-animation",
        exitActive: "fadeOutRight",
    },
} as const;

const MESSAGE_TYPE_ICONS = {
    success: {
        icon: <FontAwesomeIcon icon={faCheckCircle} className="icon-success" />,
        srText: "success",
    },
    error: {
        icon: <FontAwesomeIcon icon={faExclamationTriangle} className="icon-error" />,
        srText: "error",
    },
} as const;

export const SnackbarComponent = (props: ISnackbar) => {
    const [isMounted, setIsMounted] = useState<boolean>(INITIAL_STATE.isMounted);
    const [hideTimeout, setHideTimeout] = useState<IHideTimeout>(INITIAL_STATE.hideTimeout);

    const hideTimeoutRef: MutableRefObject<IHideTimeout | null> = useValueRef(hideTimeout);

    const shouldAutoHide = (props.options?.hideTimeout as number) > 0;

    useEffect(() => {
        setIsMounted(true);

        if (shouldAutoHide) {
            const id = setTimeout(() => setIsMounted(false), props.options?.hideTimeout as number);

            setHideTimeout({
                id,
                start: Date.now(),
                remaining: props.options?.hideTimeout as number,
            });
        }

        const hideTimeoutId = hideTimeoutRef.current?.id;

        return () => {
            clearTimeout(Number(hideTimeoutId));
        };
    }, [props.options?.hideTimeout, shouldAutoHide, hideTimeoutRef]);

    const pauseHideTimeout = () => {
        clearTimeout(hideTimeout.id ? Number(hideTimeout.id) : undefined);

        setHideTimeout({
            id: null,
            start: hideTimeout.start,
            remaining:
                (hideTimeout.remaining as number) - (Date.now() - (hideTimeout.start as number)),
        });
    };

    const resumeHideTimeout = () => {
        const id = setTimeout(() => setIsMounted(false), hideTimeout.remaining as number);

        setHideTimeout({
            id,
            start: Date.now(),
            remaining: hideTimeout.remaining,
        });
    };

    const handleMouseEnter = () => {
        if (shouldAutoHide && props.options?.pauseTimeoutOnHover) {
            pauseHideTimeout();
        }
    };

    const handleMouseLeave = () => {
        if (shouldAutoHide && props.options?.pauseTimeoutOnHover) {
            resumeHideTimeout();
        }
    };

    const getMessageIcon = () => {
        const iconType = props.options?.type && MESSAGE_TYPE_ICONS[props.options.type];

        if (iconType) {
            return (
                <span className="icon">
                    <span className="sr-only">{iconType.srText}</span>
                    {iconType.icon}
                </span>
            );
        }

        return null;
    };

    return (
        <CSSTransition
            in={isMounted}
            timeout={300}
            classNames={
                props.options?.position && SNACKBAR_TRANSITION_CLASSES[props.options.position]
            }
            onExited={props?.close}
            unmountOnExit
        >
            <div
                id={props.options?.id}
                className={classNames("stl-snackbar", props.options?.className)}
                role="alert"
                aria-live="assertive"
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                {getMessageIcon()}
                <div data-testid="alertContent" className="content">
                    {props.message}
                    {props.options?.actionButton && (
                        <StlButton
                            size="sm"
                            variant="link"
                            onClick={() => {
                                if (props.options?.onActionBtnClick)
                                    props.options.onActionBtnClick();
                                setIsMounted(false);
                            }}
                        >
                            {props.options?.actionButton}
                        </StlButton>
                    )}
                </div>
                {!props.options?.hideCloseButton && (
                    <StlButton
                        variant="naked"
                        className="close-button"
                        onClick={() => setIsMounted(false)}
                        endIcon={<FontAwesomeIcon icon={faTimes} />}
                    >
                        <span className="sr-only">Close</span>
                    </StlButton>
                )}
            </div>
        </CSSTransition>
    );
};
