import type { TRootState } from "@app/store";

export const getStaticData = (state: TRootState) => state.staticData;

export const getAvailableDataPeriods = (state: TRootState) =>
    getStaticData(state).availableDataPeriods;

export const getSATCAvailableDataPeriods = (state: TRootState) =>
    getStaticData(state).satcAvailableDataPeriods;

export const getZoneVintageDates = (state: TRootState) => getStaticData(state).zoneVintageDates;

export const getOSMLayersVintageDate = (state: TRootState) =>
    getStaticData(state).osmLayersVintageDate;
