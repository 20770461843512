import { Suspense, useMemo } from "react";
import { Redirect, Switch } from "react-router-dom";
import classNames from "classnames";
import { useAppSelector } from "@app/store/hooks";
import { StlLoader } from "@common/components";
import { getApplicationPreferences } from "@app/store/userPreferences/userPreferences.selector";
import { Header } from "@app/bigBang/layout/header/header";
import { StlNavigation } from "@app/bigBang/layout/navigation/navigation";
import { StlStatus404Page } from "@app/auth/statuses";
import { ProtectedRoute } from "@app/auth/protectedRoute/protectedRoute";
import {
    getUserRedirectPath,
    getRoutePermissions,
} from "@app/store/currentUser/currentUser.selector";
import { getBigBangRoutes } from "./bigBangLayout.routing";
import "./bigBangLayout.less";

export const BigBangLayout = () => {
    const { isNavigationMenuOpen } = useAppSelector(getApplicationPreferences);

    const defaultPath = useAppSelector(getUserRedirectPath);
    const routePermissions = useAppSelector(getRoutePermissions);

    const routes = getBigBangRoutes(routePermissions);

    const tabList = useMemo(() => routes.filter(routeData => routeData.icon), [routes]);

    return (
        <div className="stl-big-bang">
            <Header />
            <div className={classNames("content", !isNavigationMenuOpen && "closed-menu")}>
                <StlNavigation tabList={tabList} isNavigationMenuOpen={isNavigationMenuOpen} />
                <main className="main" role="main">
                    <Suspense fallback={<StlLoader show />}>
                        <Switch>
                            {routes
                                .filter(route => !!route.component)
                                .map(route => (
                                    <ProtectedRoute key={route.id} {...route} />
                                ))}
                            <Redirect exact path="/" to={defaultPath} />
                            <StlStatus404Page />
                        </Switch>
                    </Suspense>
                </main>
            </div>
        </div>
    );
};
