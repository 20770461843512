import { v4 as uuidv4 } from "uuid";
import { isEqual } from "lodash-es";
import type { IAnalysisIntersectionZone } from "@common/services/server/analysesApi.types";
import type {
    IIntersection,
    IIntersectionZone,
    IIntersectionZoneGate,
} from "@common/features/intersections/intersection.types";
import { TZoneMode, ZONES_MODES } from "@app/analysis/state/analysisConfiguration.constants";
import { INTERSECTION_GATES_LIST } from "@common/features/intersections/intersection.constants";
import { NO_SELECTED_INTERSECTION_TEXT } from "@app/analysis/zones/chooseZones/configurations/tmc/state/tmcChooseZones.constants";
import {
    validateGateDirections,
    validateGateNamesUniqueness,
    validateGateRolesUniqueness,
} from "@common/features/intersections/intersection.helpers";

export const makeGateObject = (role = "North") => {
    return {
        role,
        road: "",
        direction: undefined,
        oneWay: false,
        id: uuidv4(),
        line_geom: null,
    } as IIntersectionZoneGate;
};

export const generateDefaultIntersection = () => {
    const gates = INTERSECTION_GATES_LIST.map(gate => makeGateObject(gate.role));

    return {
        zone_name: "",
        gates,
        id: uuidv4(),
        selectedGate: null,
    };
};

export const getIntersectionZonesFromAnalysis = (
    intersectionZones?: Array<IIntersectionZone | IAnalysisIntersectionZone>,
) => {
    if (!intersectionZones) return {};

    return intersectionZones.reduce((res, intersection) => {
        const id = uuidv4();
        const gates =
            intersection.gates?.map(gate => ({
                ...gate,
                id: uuidv4(),
                role: gate.role,
                oneWay: !!gate.direction,
                direction: gate.direction ? gate.direction.toLowerCase() : undefined,
            })) || [];

        const parsedIntersection = {
            id,
            zone_id: intersection.zone_id,
            zone_name: intersection.name,
            gates,
            selectedGate: null,
            point_geometry: intersection.point_geometry,
        };
        return {
            ...res,
            [id]: parsedIntersection,
        };
    }, {});
};

// reused intersection object has only 'zone_id' property
export const getIsReusedIntersection = (intersection: IIntersection | IAnalysisIntersectionZone) =>
    isEqual(Object.keys(intersection), ["zone_id"]);

export const validateIntersections = (
    intersections: Array<IIntersectionZone>,
    zonesMode: TZoneMode,
) => {
    const hasImportedIntersections = intersections.every(intersection => !!intersection.zone_id);

    if (
        !intersections.length ||
        (zonesMode === ZONES_MODES.ZONE_SETS && !hasImportedIntersections)
    ) {
        return NO_SELECTED_INTERSECTION_TEXT;
    }

    const errors = intersections.reduce((res, intersection) => {
        // skip validation for existing intersections
        if (intersection.zone_id) {
            return res;
        }
        const hasInvalidGates = intersection.gates.some(gate => {
            return !gate.line_geom || !gate.role || !gate.road;
        });

        if (!intersection.name) {
            res.push("Intersection should have a name");
        }
        if (hasInvalidGates) {
            res.push(
                "Intersection should have a minimum of three gates, each with road, name and geometry",
            );
        }
        if (!validateGateRolesUniqueness(intersection.gates)) {
            res.push("Please provide unique roles for gates");
        }
        if (!validateGateNamesUniqueness(intersection.gates)) {
            res.push("Please provide unique street names for gates");
        }
        if (!validateGateDirections(intersection.gates)) {
            res.push("Gate directions should not be all inbound or all outbound traffic flow");
        }

        return res;
    }, [] as Array<string>);

    return errors.join(". ");
};

export const getGateRoadName = (gate: IIntersectionZoneGate) => `${gate.road}_${gate.role}`;

export const transformGatesForValidation = (gates?: Array<IIntersectionZoneGate>) => {
    return gates?.reduce(
        (gatesForValidation: Array<IIntersectionZoneGate & { road: string }>, gate) => {
            if (!gate.line_geom) return gatesForValidation;

            return [...gatesForValidation, { ...gate, road: getGateRoadName(gate) }];
        },
        [],
    );
};
