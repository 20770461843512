import {
    DAY_PARTS,
    DAY_PARTS_KINDS,
    DAY_TYPES,
    DAY_TYPES_KINDS,
} from "@app/analysis/timePeriods/state/timePeriods.constants";

export const TIME_PERIODS_INITIAL_STATE = {
    shouldUseCustomRanges: false,
    dataPeriodSettings: {
        excludedDataPeriods: [],
        excludeDateRangesEnabled: false,
        dataPeriods: [],
        customDateRanges: [],
        tempDataPeriods: [], //used for temporarily storing of dataPeriods when switching btw normal and custom periods sections
    },
    dayPartSettings: {
        dayParts: DAY_PARTS.getDefault(),
        dayPartKind: DAY_PARTS_KINDS.DAY_PART_RANGES.id,
        draftCustomDayParts: [],
    },
    dayTypeSettings: {
        dayTypes: [DAY_TYPES.ALL_DAYS, ...DAY_TYPES.DEFAULT],
        dayTypeKind: DAY_TYPES_KINDS.DAY_RANGES.id,
        draftCustomDayTypes: [],
    },
    aadtSettings: {
        aadtYear: null,
        defaultAADTYear: null,
    },
    dataMonthSettings: {
        dataMonths: [],
    },
    validation: {
        fields: {
            dataPeriods: null,
            dataMonths: null,
            excludedDataPeriods: null,
            dayParts: null,
        },
        isInvalid: false,
    },
};
