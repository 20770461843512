import { InputHTMLAttributes, useMemo } from "react";
import classnames from "classnames";
import "./checkbox.less";

export type TProps = Omit<
    InputHTMLAttributes<HTMLInputElement>,
    "onChange" | "children" | "size"
> & {
    placement?: "left" | "right";
    size?: "small" | "large";
    labelColor?: "yellow" | "white";
    onChange: (value: boolean) => void;
    ariaLabel?: string;
    testid?: string;
    children?: string | JSX.Element;
};

export const StlCheckbox = (props: TProps): JSX.Element => {
    const id = useMemo<string>(() => String(Math.random()), []);

    const placementClass =
        props.placement === "left" ? "stl-checkbox-label-left" : "stl-checkbox-label-right";
    const sizeClass = props.size === "small" ? "stl-checkbox-sm" : "stl-checkbox-lg";
    let labelColorClass = "stl-checkbox-label-white"; // default class
    if (props.disabled) {
        labelColorClass = "stl-checkbox-label-grey";
    }
    if (props.labelColor === "yellow") {
        labelColorClass = "stl-checkbox-label-yellow";
    }
    return (
        <div className="stl-checkbox" title={props.title}>
            <input
                id={id}
                type="checkbox"
                className="stl-checkbox-input"
                checked={props.checked}
                disabled={props.disabled}
                onChange={event => props.onChange(event.target.checked)}
                name={props.name}
                tabIndex={props.tabIndex || 0}
                aria-label={props.ariaLabel || (props.children as string)}
            />
            <label
                className={classnames(
                    "stl-checkbox-label",
                    placementClass,
                    sizeClass,
                    labelColorClass,
                    props.className,
                )}
                htmlFor={id}
                data-testid={props.testid}
                aria-hidden="true"
            >
                {props.children}
            </label>
        </div>
    );
};

StlCheckbox.displayName = "StlCheckbox";

StlCheckbox.defaultProps = {
    placement: "left",
    size: "large",
    labelColor: "white",
};
