import classNames from "classnames";
import "./loader.less";

type TProps = {
    show: boolean;
    useOverlay?: boolean;
    skew?: boolean;
    className?: string;
};

export const StlLoader = ({ show, useOverlay = false, skew = true, className }: TProps) => {
    return show ? (
        <div className={classNames("stl-loader", className, { "stl-loader--skew": skew })}>
            {useOverlay && <div className="overlay" />}
            <div className="spinner-ssr-wrap">
                <div className="spinner-ssr">
                    <div className="spinner-wrap">
                        <svg
                            className="loader"
                            data-name="Layer 1"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 240 240"
                        >
                            <defs />
                            <title>StreetLight Data Loading Indicator</title>
                            <path
                                className="cls-1 gear1"
                                d="M68.077,213.743l-9.062-15.7,24.494-29.192,5.243,9.08h49.883l13.034,35.809Zm83.748-36.809,4.666-8.08,24.494,29.192-8.485,14.7ZM16.154,123.811,68.077,33.878H171.923l9.062,15.7L156.491,78.767l-5.243-9.079h-62.5L57.5,123.811l19.889,34.447L49.537,181.631Zm151.218,26.195L182.5,123.811l-15.124-26.2,31.012-17.9,25.462,44.1-25.462,44.1Z"
                            />
                            <path
                                className="cls-1 gear2"
                                d="M134.742,147.346l10.438-18.079,7.81,1.377L139.045,154.8ZM83.064,123.811l2.881-4.99,7.811,1.378-2.086,3.612,11.016,19.079-5.1,6.075Zm64.689-1L134.165,99.276h-28.33l-6.583,11.4L92.8,106.951l8.733-15.127h36.936l17.889,30.987Z"
                            />
                            <path
                                className="cls-1 gear3"
                                d="M107.313,145.785,97.5,128.793l2.857-.5,8.528,14.77h22.224l1.522-2.636,1.865,2.223-1.812,3.139ZM97.5,118.829l9.81-16.992h15.546l-.48,2.726H108.888l-8.528,14.769Z"
                            />
                            <path
                                className="cls-1 gear4"
                                d="M90.266,175.312,66.559,134.25l17.716-3.124,15.751,27.281h38.793l9.76,16.905Zm57.153-29.8,12.529-21.7-19.974-34.6H114.915l-2.981-16.9h37.8l29.735,51.5-18.9,32.735Zm-66.973-56.2,3.793-6.568L95.8,96.529,93.6,100.35Z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
    ) : null;
};
