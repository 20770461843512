export const TIME_PERIODS_TAB_SECTIONS = {
    DATA_MONTHS: { code: "dataMonths" },
    DATA_PERIODS: { code: "dataPeriods" },
    DAY_TYPES: { code: "dayTypes" },
    DAY_PARTS: { code: "dayParts" },
    MONTH_RANGES: { code: "monthRanges" },
};

export type TTimePeriodsTabSection =
    typeof TIME_PERIODS_TAB_SECTIONS[keyof typeof TIME_PERIODS_TAB_SECTIONS];

export const DAY_TYPES = {
    // TODO: Find out where it's needed
    DEFAULT: [
        { name: "Weekday", start: { name: "Monday" }, end: { name: "Thursday" } },
        { name: "Weekend Day", start: { name: "Saturday" }, end: { name: "Sunday" } },
    ],
    NEW: { name: "", start: { name: "" }, end: { name: "" }, editMode: true },
    ALL_DAYS: {
        name: "All Days",
        start: { name: "Monday" },
        end: { name: "Sunday" },
        isReadOnly: true,
    },
    getDefault() {
        return [
            this.ALL_DAYS,
            { name: "Monday", start: { name: "Monday" }, end: { name: "Monday" } },
            { name: "Tuesday", start: { name: "Tuesday" }, end: { name: "Tuesday" } },
            { name: "Wednesday", start: { name: "Wednesday" }, end: { name: "Wednesday" } },
            { name: "Thursday", start: { name: "Thursday" }, end: { name: "Thursday" } },
            { name: "Friday", start: { name: "Friday" }, end: { name: "Friday" } },
            { name: "Saturday", start: { name: "Saturday" }, end: { name: "Saturday" } },
            { name: "Sunday", start: { name: "Sunday" }, end: { name: "Sunday" } },
        ];
    },
};

export const DAY_TYPES_KINDS = {
    DAY_RANGES: {
        id: "dayRanges",
        name: "Day Ranges",
        defaultOptions: [
            DAY_TYPES.ALL_DAYS,
            { name: "Monday - Thursday", start: { name: "Monday" }, end: { name: "Thursday" } },
            { name: "Friday", start: { name: "Friday" }, end: { name: "Friday" } },
            { name: "Weekend", start: { name: "Saturday" }, end: { name: "Sunday" } },
        ],
    },
    INDIVIDUAL_DAYS: {
        id: "individualDays",
        name: "Daily",
        defaultOptions: DAY_TYPES.getDefault(),
    },
};

export const DAY_TYPES_KINDS_LIST = Object.values(DAY_TYPES_KINDS);

export type TDayTypeKind = typeof DAY_TYPES_KINDS[keyof typeof DAY_TYPES_KINDS];

export const DAY_PARTS = {
    ALL_DAY: { name: "All Day", start: { name: "12am" }, end: { name: "12am" }, isReadOnly: true },
    get24Hrs() {
        return [
            this.ALL_DAY,
            { name: "12am", start: { name: "12am" }, end: { name: "1am" } },
            { name: "1am", start: { name: "1am" }, end: { name: "2am" } },
            { name: "2am", start: { name: "2am" }, end: { name: "3am" } },
            { name: "3am", start: { name: "3am" }, end: { name: "4am" } },
            { name: "4am", start: { name: "4am" }, end: { name: "5am" } },
            { name: "5am", start: { name: "5am" }, end: { name: "6am" } },
            { name: "6am", start: { name: "6am" }, end: { name: "7am" } },
            { name: "7am", start: { name: "7am" }, end: { name: "8am" } },
            { name: "8am", start: { name: "8am" }, end: { name: "9am" } },
            { name: "9am", start: { name: "9am" }, end: { name: "10am" } },
            { name: "10am", start: { name: "10am" }, end: { name: "11am" } },
            { name: "11am", start: { name: "11am" }, end: { name: "12noon" } },
            { name: "12pm", start: { name: "12noon" }, end: { name: "1pm" } },
            { name: "1pm", start: { name: "1pm" }, end: { name: "2pm" } },
            { name: "2pm", start: { name: "2pm" }, end: { name: "3pm" } },
            { name: "3pm", start: { name: "3pm" }, end: { name: "4pm" } },
            { name: "4pm", start: { name: "4pm" }, end: { name: "5pm" } },
            { name: "5pm", start: { name: "5pm" }, end: { name: "6pm" } },
            { name: "6pm", start: { name: "6pm" }, end: { name: "7pm" } },
            { name: "7pm", start: { name: "7pm" }, end: { name: "8pm" } },
            { name: "8pm", start: { name: "8pm" }, end: { name: "9pm" } },
            { name: "9pm", start: { name: "9pm" }, end: { name: "10pm" } },
            { name: "10pm", start: { name: "10pm" }, end: { name: "11pm" } },
            { name: "11pm", start: { name: "11pm" }, end: { name: "12am" } },
        ];
    },
    getDefault() {
        return [
            this.ALL_DAY,
            { name: "Early AM", start: { name: "12am" }, end: { name: "6am" } },
            { name: "Peak AM", start: { name: "6am" }, end: { name: "10am" } },
            { name: "Mid-Day", start: { name: "10am" }, end: { name: "3pm" } },
            { name: "Peak PM", start: { name: "3pm" }, end: { name: "7pm" } },
            { name: "Late PM", start: { name: "7pm" }, end: { name: "12am" } },
        ];
    },
    get15MinuteBinsDefault() {
        return [
            { name: "8am-10am", start: { name: "8am" }, end: { name: "10am" } },
            { name: "4pm-6pm", start: { name: "4pm" }, end: { name: "6pm" } },
        ];
    },
    getTMCAnalysisDefault() {
        return [
            { name: "Morning", start: { name: "6am" }, end: { name: "10am" } },
            { name: "Evening", start: { name: "3pm" }, end: { name: "7pm" } },
        ];
    },
};

export const DEFAULT_15_MINUTE_BINS = [
    { name: "8am", start: { name: "8am" }, end: { name: "9am" } },
    { name: "9am", start: { name: "9am" }, end: { name: "10am" } },
    { name: "4pm", start: { name: "4pm" }, end: { name: "5pm" } },
    { name: "5pm", start: { name: "5pm" }, end: { name: "6pm" } },
];

export const TMC_DEFAULT_15_MINUTE_BINS = [
    DAY_PARTS.ALL_DAY,
    { name: "6am", start: { name: "6am" }, end: { name: "7am" } },
    { name: "7am", start: { name: "7am" }, end: { name: "8am" } },
    { name: "8am", start: { name: "8am" }, end: { name: "9am" } },
    { name: "9am", start: { name: "9am" }, end: { name: "10am" } },
    { name: "3pm", start: { name: "3pm" }, end: { name: "4pm" } },
    { name: "4pm", start: { name: "4pm" }, end: { name: "5pm" } },
    { name: "5pm", start: { name: "5pm" }, end: { name: "6pm" } },
    { name: "6pm", start: { name: "6pm" }, end: { name: "7pm" } },
];

export const DAY_PARTS_KINDS = {
    DAY_PART_RANGES: {
        id: "dayPartRanges",
        name: "Day Parts",
        defaultOptions: DAY_PARTS.getDefault(),
    },
    INDIVIDUAL_HOURS: {
        id: "individualHours",
        name: "Hourly",
        defaultOptions: DAY_PARTS.get24Hrs(),
    },
};

export const DAY_PARTS_KINDS_LIST = Object.values(DAY_PARTS_KINDS);

export type TDayPartKind = typeof DAY_PARTS_KINDS[keyof typeof DAY_PARTS_KINDS];

export const DAY_CODES = {
    Monday: { name: "Monday", code: "1", shortName: "M" },
    Tuesday: { name: "Tuesday", code: "2", shortName: "Tu" },
    Wednesday: { name: "Wednesday", code: "3", shortName: "W" },
    Thursday: { name: "Thursday", code: "4", shortName: "Th" },
    Friday: { name: "Friday", code: "5", shortName: "F" },
    Saturday: { name: "Saturday", code: "6", shortName: "Sa" },
    Sunday: { name: "Sunday", code: "7", shortName: "Su" },
} as const;
export type TDayCode = typeof DAY_CODES[keyof typeof DAY_CODES];

export const getDayShortNameFromCode = (code: TDayCode["code"]) => {
    return Object.values(DAY_CODES).find(day => {
        return day.code === code;
    })!.shortName;
};

export const DAY_CODES_LIST = Object.values(DAY_CODES);

export const HOURS_CODES = {
    "12am": { name: "12am", code: "0000" },
    "1am": { name: "1am", code: "0100" },
    "2am": { name: "2am", code: "0200" },
    "3am": { name: "3am", code: "0300" },
    "4am": { name: "4am", code: "0400" },
    "5am": { name: "5am", code: "0500" },
    "6am": { name: "6am", code: "0600" },
    "7am": { name: "7am", code: "0700" },
    "8am": { name: "8am", code: "0800" },
    "9am": { name: "9am", code: "0900" },
    "10am": { name: "10am", code: "1000" },
    "11am": { name: "11am", code: "1100" },
    "12noon": { name: "12noon", code: "1200" },
    "1pm": { name: "1pm", code: "1300" },
    "2pm": { name: "2pm", code: "1400" },
    "3pm": { name: "3pm", code: "1500" },
    "4pm": { name: "4pm", code: "1600" },
    "5pm": { name: "5pm", code: "1700" },
    "6pm": { name: "6pm", code: "1800" },
    "7pm": { name: "7pm", code: "1900" },
    "8pm": { name: "8pm", code: "2000" },
    "9pm": { name: "9pm", code: "2100" },
    "10pm": { name: "10pm", code: "2200" },
    "11pm": { name: "11pm", code: "2300" },
};

export const HOURS_CODES_LIST = Object.values(HOURS_CODES);

export const HOUR_12PM_CODE = { name: "12pm", code: "1200" };

export const PRESET_YEARS = [2023, 2022, 2021, 2020, 2019, 2018];
export const PRESET_YEAR_OPTIONS = PRESET_YEARS.map(year => ({
    value: year,
    label: year,
}));
export type TPresetYearOption = typeof PRESET_YEAR_OPTIONS[number];

export const BIKE_PED_PRESET_YEARS = [2021, 2020, 2019];
export const BIKE_PED_PRESET_YEAR_OPTIONS = BIKE_PED_PRESET_YEARS.map(year => ({
    value: year,
    label: year,
}));
export type TBikePedPresetYearOption = typeof BIKE_PED_PRESET_YEAR_OPTIONS[number];

export const PRESET_OPTIONS = {
    DEFAULT: { value: "Default", label: "Default" },
    CUSTOM: { value: "Custom", label: "Custom" },
};
export type TPresetOption = typeof PRESET_OPTIONS[keyof typeof PRESET_OPTIONS];

// Year which separates dates before/after US Census 2020
export const CENSUS_2020_THRESHOLD_YEAR = 2019;

// Allowed data periods years for Truck Volume analyses
export const TRUCK_VOLUME_YEARS = [2019, 2020, 2021];

// Partial data years for ALL_VEHICLES_CVD travel mode
export const ALL_VEHICLES_CVD_PARTIAL_YEARS = [2023];

export const CVD_UPSAMPLED_METRICS_DATES = [
    {
        startDate: new Date(2024, 0, 1),
        endDate: new Date(2024, 0, 2),
    },
];

export const TIME_PERIODS_SWITCH_PATTERNS_ON_TRAVEL_MODE_CHANGE = {
    PERIODS_PERIODS: { id: "PERIODS_PERIODS", from: "dataPeriods", to: "dataPeriods" },
    PERIODS_MONTHS: { id: "PERIODS_MONTHS", from: "dataPeriods", to: "dataMonths" },
    MONTHS_MONTHS: { id: "MONTHS_MONTHS", from: "dataMonths", to: "dataMonths" },
    MONTHS_PERIODS: { id: "MONTHS_PERIODS", from: "dataMonths", to: "dataPeriods" },
};
