import chroma from "chroma-js";
import moment from "moment";
import { difference } from "lodash-es";
import { isLabelLayer } from "@common/utils/mapUtils";
import { formatValue, numberToPercentage } from "@common/formatters/formatValue";
import {
    getAttributeMeasurementUnit,
    getMeasurementUnitById,
} from "@common/helpers/measurementUnits.helpers";
import {
    getAnalysisTravelModeDisplayName,
    getIsAllVehiclesTravelMode,
    getIsNetworkPerformanceAnalysis,
    getIsTMCAnalysis,
} from "@common/helpers/analysis";
import { ANALYSIS_TYPES, MODES_OF_TRAVEL } from "@common/constants/analysis.constants";
import { ZONE_KINDS_LIST } from "@common/constants/zoneLibrary.constants";
import {
    FIFTEEN_MINUTES_BIN_GRANULARITY,
    CALIBRATIONS,
    CREATE_ANALYSIS_TYPES,
} from "@app/analysis/state/analysisConfiguration.constants";
import {
    getAnalysisCalibrationCode,
    getIsLightningAnalysis,
} from "@app/analysis/state/analysisConfiguration.helpers";
import { VIZ_SCREENSHOTS_COMPONENTS } from "@app/viz3/screenshots/viz3Screenshots.constants";
import {
    ANALYSIS_CODES_MIGRATED_ON_TS,
    VISUALIZATION_BY_ANALYSIS_TYPE,
} from "@app/viz3/base/visualizationsConfiguration";
import {
    DAY_PARTS,
    MAX_SELECTED_ZONE_COUNT,
    MEASUREMENT_TYPES,
    PROMOTE_ID_PROPERTY_NAME,
    TIME_FILTERS,
    VIZ_ZONE_TYPES_LIST,
    READ_ONLY_ZONE_TYPES,
    TRUCK_CALIBRATED_INDEX_OUTPUT_TYPE_NAME,
    TRUCK_VOLUME_OUTPUT_TYPE_NAME,
    WEIGHT_CLASS_TYPES,
    TRUCK_INDEX_OUTPUT_TYPE_NAME,
    GROUP_MODE_FILTER,
} from "@app/viz3/base/state/baseViz.constants";
import {
    getAvailableDeviceSegmentations,
    getInitialIntersectionTypes,
} from "@app/viz3/zaVisualization/state/zaViz.helpers";
import {
    VISUALIZATION_PROPERTIES,
    VISUALIZATION_PROPERTIES_LIST,
} from "@app/viz3/segmentVisualization/state/segmentViz.constants";
import {
    PEAK_HOUR_OPTIONS_LIST,
    DAY_PART_TYPE_OPTIONS,
    PEAK_HOUR_FILTER,
} from "@app/viz3/tmcVisualization/state/tmcViz.constants";
import { CUSTOM_GATE_TIME_DISTRIBUTION_METRIC_KEY } from "@app/viz3/npSpotVisualization/widgets/timeDistribution/npSpotTimeDistribution.constants";

export const createZoneSelectOptions = (zones = []) => {
    return zones.map(value => ({
        label: String(value.zone_name || value.zone_id),
        value: value.zone_id,
        zoneKindId: value.zone_kind_id,
    }));
};

export const getBaseMapLayers = map => {
    if (!map) {
        return [];
    }

    const { layers = [] } = map.getStyle();
    return layers.filter(layer => !layer.id.startsWith("stl:"));
};

export const getLabelMapLayers = map => {
    if (!map) {
        return [];
    }

    const { layers = [] } = map.getStyle();
    return layers.filter(isLabelLayer);
};

export const getFirstDistributionLayerId = map => {
    const { layers = [] } = map.getStyle();
    const firstDistributionLayer = layers.find(layer => layer.id.startsWith("stl:distribution:"));

    return firstDistributionLayer?.id;
};

export const generatePromoteId = layers =>
    layers.reduce((config, layer) => {
        config[layer.id] = PROMOTE_ID_PROPERTY_NAME;

        return config;
    }, {});

export const getTopMostFeature = (e, map, layers) => {
    if (e.features?.length) {
        const [topMostFeature] = map
            .queryRenderedFeatures(e.point)
            .filter(feature => layers.some(layer => layer.id === feature.layer.id))
            .sort(({ layer: layerA }, { layer: layerB }) => {
                const zIndexA = layerA.metadata?.zIndex || 1;
                const zIndexB = layerB.metadata?.zIndex || 1;
                return zIndexB - zIndexA;
            });
        return topMostFeature;
    } else {
        return null;
    }
};

export const calculateBinColors = (colors, binsCount) => {
    const hasMidColor = !!colors.mid;
    const colorsRange = [colors.low, ...(colors.mid ? [colors.mid] : []), colors.high];

    if (hasMidColor) {
        return chroma.scale(colorsRange).colors(binsCount);
    } else {
        return chroma
            .bezier([colors.low, ...(colors.mid ? [colors.mid] : []), colors.high])
            .scale()
            .mode("lab")
            .correctLightness()
            .colors(binsCount);
    }
};

export const calculateDataDependentBinColors = (colors, bins) => {
    if (!colors.mid) return calculateBinColors(colors, bins.length);

    const negativeValueBins = [];
    const positiveValueBins = [];
    const colorBins = [];

    bins.forEach(bin => (bin < 0 ? negativeValueBins.push(bin) : positiveValueBins.push(bin)));

    const negativeValueColors = chroma
        .scale([colors.low, colors.mid])
        .mode("lab")
        .correctLightness()
        .colors(negativeValueBins.length);

    const positiveValueColors = chroma
        .scale([colors.mid, colors.high])
        .mode("lab")
        .correctLightness()
        .colors(positiveValueBins.length + 1);

    if (negativeValueBins.length > 1) {
        colorBins.push(...negativeValueColors);
    } else {
        colorBins.push(colors.mid);
    }

    if (positiveValueBins.length) {
        colorBins.push(...positiveValueColors.slice(1));
    }

    return colorBins;
};

export const createBinItems = ({ zoneScores, statistics, measurement, colors }) => {
    if (!zoneScores || !statistics || !statistics.total_count) return [];

    let bins;
    switch (measurement?.code) {
        case MEASUREMENT_TYPES.WEIGHT_CLASS_PERCENT.code: {
            bins = zoneScores.bins_weight_class_pct;
            break;
        }
        case MEASUREMENT_TYPES.TRIP_PROPORTION.code: {
            bins = zoneScores.bins_trip_proportion;
            break;
        }
        default: {
            bins = zoneScores.bins;
        }
    }

    const totalCount = statistics.total_count;
    const binStarts = bins.kmeans;
    const binColors = calculateBinColors(colors, binStarts.length);

    const getBinLabel = (binStart, binEnd) => {
        let _binStart;
        let _binEnd;

        switch (measurement?.code) {
            case MEASUREMENT_TYPES.PERCENT.code: {
                _binStart = numberToPercentage(binStart, totalCount);
                _binEnd = numberToPercentage(binEnd, totalCount);
                break;
            }
            case MEASUREMENT_TYPES.WEIGHT_CLASS_PERCENT.code: {
                _binStart = formatValue(binStart, { shouldShowAsPercentage: true });
                _binEnd = formatValue(binEnd, { shouldShowAsPercentage: true });
                break;
            }
            case MEASUREMENT_TYPES.TRIP_PROPORTION.code: {
                _binStart = `${formatValue(binStart, { shouldShowAsFractional: true })}%`;
                _binEnd = `${formatValue(binEnd, { shouldShowAsFractional: true })}%`;
                break;
            }
            default: {
                _binStart = Math.round(binStart);
                _binEnd = Math.round(binEnd);
            }
        }

        if (parseFloat(_binEnd) > parseFloat(_binStart)) return `${_binStart}-${_binEnd}`;
        return `${_binStart}`;
    };

    const getBinEnd = (binStart, index) => {
        const isWeightClassPercentage =
            measurement?.code === MEASUREMENT_TYPES.WEIGHT_CLASS_PERCENT.code;

        const isTripProportion = measurement?.code === MEASUREMENT_TYPES.TRIP_PROPORTION.code;

        const increment = isWeightClassPercentage || isTripProportion ? 0.0001 : 1;

        if (index < binStarts.length - 1) {
            const tryBinEnd = binStarts[index + 1] - increment;
            // If single-valued bin, set binEnd to binStart
            return tryBinEnd >= binStarts[index] ? tryBinEnd : binStarts[index];
        } else {
            // use max value as end of last bin
            if (isWeightClassPercentage) {
                return statistics.max_weight_class_pct;
            }
            if (isTripProportion) {
                return statistics.max_trip_proportion;
            }
            return statistics.max;
        }
    };

    return binStarts
        .map((binStart, index) => {
            const binEnd = getBinEnd(binStart, index);

            return {
                color: binColors[index],
                label: getBinLabel(binStart, binEnd),
            };
        })
        .reverse();
};

export const getZoneType = typeName => {
    return VIZ_ZONE_TYPES_LIST.find(zoneType => zoneType.typeName === typeName);
};

export const getAllAvailableZones = selectedAnalysis => {
    return VIZ_ZONE_TYPES_LIST.reduce((map, type) => {
        const zonesByZoneKind = selectedAnalysis?.[type.zonesAccessor] || {};

        map[type.typeName] = Object.values(zonesByZoneKind)
            .flat()
            .map(zone => {
                const zoneId = type.zoneIdAttribute ? zone[type.zoneIdAttribute] : zone;
                const zoneName = type.zoneNameAttribute
                    ? zone[type.zoneNameAttribute]
                    : zone.zone_name;

                return {
                    ...zone,
                    zone_id: String(zoneId),
                    zone_name: zoneName || String(zoneId),
                    type: type.typeName,
                    typeDisplayName: type.displayName,
                    geomType: type.geomType || zone.geom_type,
                };
            }, []);

        return map;
    }, {});
};

export const getVisualizationPropertyFullLabel = ({
    property,
    calibration,
    measurementUnit,
    travelModeCode,
}) => {
    const vizProperty = VISUALIZATION_PROPERTIES_LIST.find(
        option => option.code === property?.code,
    );

    if (vizProperty.code === VISUALIZATION_PROPERTIES.VHD.code) {
        const unitName = getMeasurementUnitById(measurementUnit)?.singularName;
        return `${vizProperty.label} (Per ${unitName})`;
    }
    // VISUALIZATION_PROPERTIES.VOLUME label depends on the analysis output type:
    // - Volume as an output -> Average Volume
    // - Index as an output -> Average [Travel Mode] Index
    // - Sample Trip Counts as an output -> Average [Travel Mode] Sample Counts
    if (vizProperty.code === VISUALIZATION_PROPERTIES.VOLUME.code) {
        const travelModeName = getIsAllVehiclesTravelMode(travelModeCode)
            ? MODES_OF_TRAVEL.ALL_VEHICLES_ONLY.name
            : getAnalysisTravelModeDisplayName(travelModeCode);

        switch (calibration.code) {
            case CALIBRATIONS.INDEX.code:
                return `Average ${travelModeName} Index`;
            case CALIBRATIONS.TRIP_COUNTS.code:
                return `Average ${travelModeName} Sample Counts`;
            default:
                return vizProperty.label;
        }
    }
    if (calibration && vizProperty.code === VISUALIZATION_PROPERTIES.TRAFFIC.code) {
        return `${vizProperty.label} (${calibration.label})`;
    }

    const unit = getAttributeMeasurementUnit({ attribute: vizProperty, measurementUnit });

    if (unit) return `${vizProperty.label} (${unit})`;

    return vizProperty.label;
};

export const getDayPartsAndTypes = analysis => {
    const { day_parts = [], day_types = [] } = analysis;

    return {
        [TIME_FILTERS.DAY_PARTS.filterName]: day_parts.map(value => ({
            label: value.day_part_desc,
            value: value.day_part8,
        })),
        [TIME_FILTERS.DAY_TYPES.filterName]: day_types.map(value => ({
            label: value.day_type_desc,
            value: value.day_type,
        })),
    };
};

export const getAnalysisConfigFilters = ({ config, defaults, analysis }) => {
    const isLightningAnalysis = getIsLightningAnalysis(analysis.project_type);
    const newFilters = {
        ...defaults,
        ...config.filters,
    };

    if (!isLightningAnalysis) {
        const allAvailableZones = getAllAvailableZones(analysis);

        // Fill Zones filters
        VIZ_ZONE_TYPES_LIST.forEach(zoneType => {
            // if we have at least one zone for zone type, don't need to set defaults
            if (newFilters[zoneType.filterName]?.length) {
                const isReadOnlyZoneType = READ_ONLY_ZONE_TYPES.includes(zoneType.typeName);
                const zoneIds = newFilters[zoneType.filterName];
                const selectedZones = isReadOnlyZoneType
                    ? allAvailableZones[zoneType.typeName]
                    : allAvailableZones[zoneType.typeName].filter(zone =>
                          zoneIds.includes(zone.zone_id),
                      );

                newFilters[zoneType.filterName] = createZoneSelectOptions(selectedZones);
            } else {
                newFilters[zoneType.filterName] = createZoneSelectOptions(
                    allAvailableZones[zoneType.typeName],
                );
            }
        });
    }

    // Fill group mode filter
    const groupModeFilterValue = config.filters?.[GROUP_MODE_FILTER.filterName];
    newFilters[GROUP_MODE_FILTER.filterName] = GROUP_MODE_FILTER.allowedValues.includes(
        groupModeFilterValue,
    )
        ? groupModeFilterValue
        : GROUP_MODE_FILTER.defaultValue;

    // Fill Day Parts and Day Types filters
    const dayPartsAndTypes = getDayPartsAndTypes(analysis);

    Object.keys(TIME_FILTERS).forEach(timeFilter => {
        const { filterName } = TIME_FILTERS[timeFilter];
        const _dayPartsAndTypes = dayPartsAndTypes[filterName];

        const selectedDayParts = config.filters?.[filterName]?.length
            ? _dayPartsAndTypes.filter(option => {
                  return config.filters?.[filterName]?.find(filterItem => {
                      return typeof filterItem === "object"
                          ? filterItem.value === option.value
                          : filterItem === option.value;
                  });
              })
            : _dayPartsAndTypes.filter(option => newFilters[filterName]?.includes(option.value));

        let defaultValues;
        // For analysis with 15-minute bins, set all available day parts as default filter values
        if (filterName === TIME_FILTERS.DAY_PARTS.filterName && analysis.has_min_bins) {
            defaultValues = _dayPartsAndTypes.filter(
                dayPart => dayPart.value !== DAY_PARTS.ALL_DAY.value,
            );
        } else {
            defaultValues =
                _dayPartsAndTypes.length &&
                analysis.project_type !== ANALYSIS_TYPES.CT.id &&
                analysis.project_type !== ANALYSIS_TYPES.CS.id
                    ? [_dayPartsAndTypes[0]]
                    : defaults[filterName];
        }
        newFilters[filterName] = selectedDayParts.length ? selectedDayParts : defaultValues;
    });

    // Fill Peak Hours filter for TMC analysis
    if (getIsTMCAnalysis(analysis.project_type)) {
        if (newFilters.dayPartType === DAY_PART_TYPE_OPTIONS.PEAK_HOURS.value) {
            newFilters[PEAK_HOUR_FILTER.filterName] = newFilters[PEAK_HOUR_FILTER.filterName].map(
                peakHour => PEAK_HOUR_OPTIONS_LIST.find(option => option.value === peakHour),
            );
            newFilters[TIME_FILTERS.DAY_PARTS.filterName] = [];
        } else {
            newFilters[PEAK_HOUR_FILTER.filterName] = [];
        }
    }

    return newFilters;
};

export const getIsTopRoutesAnalysis = analysisData =>
    analysisData.project_type === CREATE_ANALYSIS_TYPES.TOP_ROUTES_ZA.code ||
    analysisData.project_type === CREATE_ANALYSIS_TYPES.TOP_ROUTES_OD.code;

export const getAnalysisDefaultMeasurement = analysisData => {
    const calibrationCode = getAnalysisCalibrationCode(analysisData);
    const outputName = analysisData.output_type_name;

    const isTopRoutesAnalysis = getIsTopRoutesAnalysis(analysisData);

    if (isTopRoutesAnalysis && calibrationCode === CALIBRATIONS.INDEX.code) {
        return MEASUREMENT_TYPES.TRIP_PROPORTION;
    }

    if (calibrationCode !== CALIBRATIONS.INDEX.code) {
        return {
            code: calibrationCode,
            label: outputName,
        };
    } else {
        return MEASUREMENT_TYPES.PERCENT;
    }
};

export const getIsTruckVolumeAnalysis = analysis => {
    const travelModeName = getAnalysisTravelModeDisplayName(analysis.travel_mode_type);
    const isTruckAnalysis = travelModeName === MODES_OF_TRAVEL.TRUCK.name;
    const isVolumeAnalysis = analysis.output_type_name === TRUCK_VOLUME_OUTPUT_TYPE_NAME;

    return isTruckAnalysis && isVolumeAnalysis;
};

export const getIsTruckCalibratedIndexAnalysis = analysis => {
    const travelModeName = getAnalysisTravelModeDisplayName(analysis.travel_mode_type);
    const isTruckAnalysis = travelModeName === MODES_OF_TRAVEL.TRUCK.name;
    const isCalibratedIndexAnalysis =
        analysis.output_type_name === TRUCK_CALIBRATED_INDEX_OUTPUT_TYPE_NAME;

    return isTruckAnalysis && isCalibratedIndexAnalysis;
};

export const getIsTruckIndexAnalysis = analysis => {
    const travelModeName = getAnalysisTravelModeDisplayName(analysis.travel_mode_type);
    const isTruckAnalysis = travelModeName === MODES_OF_TRAVEL.TRUCK.name;
    const isIndexAnalysis = analysis.output_type_name === TRUCK_INDEX_OUTPUT_TYPE_NAME;

    return isTruckAnalysis && isIndexAnalysis;
};
/*
 Checks if analysis supports Vehicle Weight Class metrics:
 - analyses with 'All Vehicles By Weight' travel mode (only AADT analyses support this mode)
 - Truck analyses when output type is Truck Volume (CALIBRATIONS.VOLUME) or Calibrated Index (CALIBRATIONS.USER_COUNTS)
*/
export const getIsWeightClassMetricsAnalysis = analysis => {
    const travelModeName = getAnalysisTravelModeDisplayName(analysis.travel_mode_type);
    const isAllVehiclesByWeightAnalysis =
        travelModeName === MODES_OF_TRAVEL.ALL_VEHICLES_BY_WEIGHT.name;
    const isTruckAnalysis = travelModeName === MODES_OF_TRAVEL.TRUCK.name;
    const isVolumeOrIndexAnalysis = [
        TRUCK_VOLUME_OUTPUT_TYPE_NAME,
        TRUCK_CALIBRATED_INDEX_OUTPUT_TYPE_NAME,
    ].includes(analysis.output_type_name);

    return (isTruckAnalysis && isVolumeOrIndexAnalysis) || isAllVehiclesByWeightAnalysis;
};

export const getWeightFilterOptions = analysis => {
    if (!analysis.enable_veh_weight) return [];

    return [
        ...(analysis.include_comm_heavy ? [WEIGHT_CLASS_TYPES.HEAVY_TRUCKS] : []),
        ...(analysis.include_comm_medium ? [WEIGHT_CLASS_TYPES.MEDIUM_TRUCKS] : []),
        ...(analysis.include_comm_light ? [WEIGHT_CLASS_TYPES.LIGHT_TRUCKS] : []),
    ];
};

export const getInitialVehicleWeightClasses = (visualization, analysis) => {
    const isMultiWeightSegmentation = getIsWeightClassMetricsAnalysis(analysis);
    const weightClassOptions = Array.isArray(visualization.vehicleWeightClass)
        ? visualization.vehicleWeightClass
        : getWeightFilterOptions(analysis);

    if (isMultiWeightSegmentation) {
        return weightClassOptions;
    }
    return weightClassOptions.length ? [weightClassOptions[0]] : [];
};

export const getAnalysisConfigVisualizations = ({ config, defaults, analysis }) => {
    const visualization = config.visualization || {};
    const outputOption = getAnalysisDefaultMeasurement(analysis);
    const trafficBehaviors = visualization.trafficBehaviors || defaults.trafficBehaviors;

    const isTopRoutesAnalysis = getIsTopRoutesAnalysis(analysis);
    if (
        isTopRoutesAnalysis &&
        visualization?.measurement?.code === MEASUREMENT_TYPES.PERCENT.code
    ) {
        visualization.measurement = MEASUREMENT_TYPES.TRIP_PROPORTION;
    }

    return {
        ...defaults,
        ...visualization,
        mapLayersVisibility: {
            ...defaults.mapLayersVisibility,
            ...visualization.mapLayersVisibility,
        },
        // Default measurement option depends on analysis calibration code
        measurement: {
            ...outputOption,
            ...visualization.measurement,
        },
        vehicleWeightClass: getInitialVehicleWeightClasses(visualization, analysis),
        deviceSegmentations: Array.isArray(visualization.deviceSegmentations)
            ? visualization.deviceSegmentations
            : getAvailableDeviceSegmentations(trafficBehaviors?.[0], analysis),
        intersectionTypes: defaults.intersectionTypes
            ? getInitialIntersectionTypes(visualization, analysis)
            : [],
    };
};

export const convert15MinBinsToFilterOptions = dayParts => {
    return Object.keys(dayParts)
        .filter(key => dayParts[key])
        .map(dayPart => {
            const binStart = dayPart.substr(1, 4);
            const binEnd = dayPart.substr(5, 4);
            const start = moment(binStart, "HHmm");
            const end = moment(binEnd, "HHmm").add(1, "minute");

            return {
                label: `${start.format("h:mma")} - ${end.format("h:mma")}`,
                value: Number(dayPart),
            };
        });
};

export const convert15MinBinsToDayParts = selectedDayParts => {
    const dayParts = selectedDayParts.map(dayPart => {
        const value = String(dayPart.value);
        return value.substr(1, value.length - 1);
    });

    const ranges = [];
    let start = moment(dayParts[0], "HH:mm");
    for (let i = 0; i <= dayParts.length; i++) {
        const curBinPlus15 = moment(dayParts[i], "HH:mm").add(
            FIFTEEN_MINUTES_BIN_GRANULARITY,
            "minutes",
        );
        const nextPart = moment(dayParts[i + 1], "HH:mm");

        if (curBinPlus15.format("HHmm") !== nextPart.format("HHmm")) {
            const truncatedEndBin = moment(curBinPlus15).subtract(1, "minutes").format("HHmm");
            const endBin = moment(curBinPlus15).format("h:mma");

            ranges.push({
                value: Number(`8${start.format("HHmm")}${truncatedEndBin}`),
                label: `${start.format("h:mma")} -- ${endBin}`,
            });
            start = moment(dayParts[i + 1], "HH:mm");
        }
    }

    return ranges;
};

export const isAnalysisTypeSupported = analysis => {
    const supportedAnalysisTypes = Object.keys(VISUALIZATION_BY_ANALYSIS_TYPE);

    return supportedAnalysisTypes.includes(analysis.project_type);
};

export const getExcludedZones = ({
    selectedZones = [],
    availableZones = [],
    maxSelectedZoneCount = MAX_SELECTED_ZONE_COUNT,
}) => {
    if (
        selectedZones.length < availableZones.length - maxSelectedZoneCount ||
        !selectedZones.length ||
        selectedZones.length <= maxSelectedZoneCount
    ) {
        return [];
    }

    return difference(availableZones, selectedZones);
};

export const timeFilterValidator = (errors, context) => {
    const { filters } = context;
    const timeFilters = !!(
        filters[TIME_FILTERS.DAY_TYPES.filterName]?.length &&
        filters[TIME_FILTERS.DAY_PARTS.filterName]?.length
    );
    if (!timeFilters) {
        errors.push("Please select at least one day type and day part");
    }

    return errors;
};

export const commercialSegmentationFilterValidator = (errors, context) => {
    const { filters, selectedAnalysis } = context;

    if (selectedAnalysis.enable_veh_weight && !filters.vehicle_weight_ids?.length) {
        errors.push("Please select at least one Commercial Truck Segment");
    }

    return errors;
};

export const getVizSelectorsModule = analysisType => {
    const { code: vizCode } = VISUALIZATION_BY_ANALYSIS_TYPE[analysisType];

    return ANALYSIS_CODES_MIGRATED_ON_TS.includes(vizCode)
        ? import(`../../${vizCode}Visualization/state/${vizCode}Viz.selectors.ts`)
        : import(`../../${vizCode}Visualization/state/${vizCode}Viz.selectors.js`);
};

export const makeScreenshotsRequestBody = ({
    components,
    measurement,
    vizState,
    filtersSnapshot,
}) => {
    const body = {
        project_id: vizState.base.selectedAnalysis.project_id,
        context_json: {
            component_list: components,
            measurement,
            state: vizState,
        },
        filter_settings: filtersSnapshot,
    };

    if (components.some(component => component.id.includes(VIZ_SCREENSHOTS_COMPONENTS.MAP.id))) {
        const mapNode = document.querySelector(VIZ_SCREENSHOTS_COMPONENTS.MAP.nodeId);

        body.context_json.map_viewport = {
            height: mapNode.offsetHeight,
            width: mapNode.offsetWidth,
        };
    }

    return body;
};

export const getCsvExportChartParams = ({
    chartParams,
    chartType,
    apiFilters,
    analysisType,
    filterHash,
}) => {
    const visualizationProperty = chartParams.visualization_property
        ? { visualization_property: chartParams.visualization_property }
        : {};

    const isNetworkPerformanceAnalysis = getIsNetworkPerformanceAnalysis(analysisType);

    if (isNetworkPerformanceAnalysis && chartType === CUSTOM_GATE_TIME_DISTRIBUTION_METRIC_KEY) {
        return {
            ...chartParams,
            filter_hash: filterHash,
        };
    }

    if (chartParams) {
        return {
            ...chartParams,
            ...apiFilters,
            ...visualizationProperty,
        };
    }
    return apiFilters;
};

export const getVizZonesByZoneKind = zones => {
    if (!zones?.length) return { zones: [] };

    const zonesData = zones.reduce((result, zone) => {
        const zoneIds = result[zone.zoneKindId]?.zone_ids || [];

        result[zone.zoneKindId] = {
            zone_kind_id: zone.zoneKindId,
            zone_ids: [...zoneIds, zone.value],
        };
        return result;
    }, {});
    return { zones: Object.values(zonesData) };
};

export const getZoneKinds = analysis => {
    if (!analysis) return null;

    const zoneKinds = VIZ_ZONE_TYPES_LIST.reduce((result, type) => {
        const zonesByZoneKind = analysis[type.zonesAccessor];

        if (!zonesByZoneKind) return result;

        Object.keys(zonesByZoneKind).forEach(id => {
            if (result[id]) return;

            const zoneKind = ZONE_KINDS_LIST.find(kind => kind.id.includes(Number(id)));

            if (!zoneKind) return;

            result[zoneKind.id[0]] = zoneKind;
        });

        return result;
    }, {});

    return Object.values(zoneKinds);
};

export const isOtherZone = zoneId => String(zoneId).includes("-");
