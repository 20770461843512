import { uniqBy, isEqual } from "lodash-es";
import { Map, Layer, LngLat, LngLatBounds, Expression, LngLatLike } from "mapbox-gl";
import type { GeoJsonProperties, Geometry, MultiPolygon, Polygon, Position } from "geojson";
import bearing from "@turf/bearing";
import type { THighlightZoneProps } from "@common/components/baseMap/hooks/useHover";
import type {
    TGeometryCoordinates,
    TMapRegion,
    TPrevLayer,
    EMapStyleIds,
    TMapStyleProvider,
} from "@common/components/baseMap/baseMap.types";
import { ESRI_ACCESS_TOKEN } from "@common/constants/mapbox.constants";
import { MAP_STYLE_PROVIDERS } from "@common/components/baseMap/baseMap.constants";

export const getGeometryCoordinates = (geometry: Geometry): Position[] => {
    switch (geometry.type) {
        case "LineString":
            return geometry.coordinates;
        case "Polygon":
            return geometry.coordinates.flat();
        case "MultiPolygon":
            return geometry.coordinates.flat(2);
        default:
            throw new Error("Unknown geometry type");
    }
};

export const calculateBounds = (type: Geometry["type"], coordinates: TGeometryCoordinates) => {
    const _coordinates = getGeometryCoordinates({ type, coordinates } as Geometry);

    return _coordinates.reduce((bound, coord) => {
        return bound.extend(coord as LngLatLike);
    }, new LngLatBounds(_coordinates[0] as LngLatLike, _coordinates[0] as LngLatLike));
};

export const getTopMostNorthSouthPoints = (coordinates: Position[] = []) => {
    if (!coordinates.length) return { north: [], south: [] };

    const [firstPoint, ...lngLatPoints] = coordinates.map(
        coordinate => new LngLat(coordinate[0], coordinate[1]),
    );

    const cardinalPoints = {
        north: [firstPoint],
        south: [firstPoint],
    };

    lngLatPoints.forEach(point => {
        const [northPoint] = cardinalPoints.north;
        const [southPoint] = cardinalPoints.south;

        if (point.lat > northPoint.lat) {
            cardinalPoints.north = [point];
        } else if (point.lat === northPoint.lat) {
            cardinalPoints.north = [...cardinalPoints.north, point];
        }

        if (point.lat < southPoint.lat) {
            cardinalPoints.south = [point];
        } else if (point.lat === southPoint.lat) {
            cardinalPoints.south = [...cardinalPoints.south, point];
        }
    });

    return cardinalPoints;
};

export const getNorthWestPoint = (geometry: Geometry) => {
    const coordinates = getGeometryCoordinates(geometry);

    const { north } = getTopMostNorthSouthPoints(coordinates);

    if (north.length === 1) return north[0];

    const sortedWesternToEasternPoints = north.sort((pointA, pointB) => {
        return pointA.lng - pointB.lng;
    });

    return sortedWesternToEasternPoints[0];
};

export const getSouthEastPoint = (geometry: Geometry) => {
    const coordinates = getGeometryCoordinates(geometry);

    const { south } = getTopMostNorthSouthPoints(coordinates);

    if (south.length === 1) return south[0];

    const sortedEasternToWesternPoints = south.sort((pointA, pointB) => {
        return pointB.lng - pointA.lng;
    });

    return sortedEasternToWesternPoints[0];
};

export const getSouthWestPoint = (geometry: Geometry) => {
    const coordinates = getGeometryCoordinates(geometry);

    const { south } = getTopMostNorthSouthPoints(coordinates);

    if (south.length === 1) return south[0];

    const sortedWesternToEasternPoints = south.sort((pointA, pointB) => {
        return pointA.lng - pointB.lng;
    });

    return sortedWesternToEasternPoints[0];
};

export const regionToGeometry = (region?: TMapRegion) => {
    if (!region) return null;

    const { type, coordinates } = JSON.parse(region.buffered_geometry);

    return { type, coordinates };
};

export const geometryToDrawnRegion = (geometry: Polygon | MultiPolygon) => {
    /* upload shapefile returns geometry of type MultiPolygon, while mapbox-gl-draw returns geometry of type Polygon.*/
    const coordinates =
        geometry.coordinates[0].length > 1
            ? (geometry.coordinates as Polygon["coordinates"])[0]
            : (geometry.coordinates as MultiPolygon["coordinates"])[0][0];

    return coordinates.map((coordinate: Position) => ({
        lng: coordinate[0],
        lat: coordinate[1],
    }));
};

const toggleHoverState = (map: Map, layer: Layer, featureId: number | string, state: boolean) => {
    if (!map.getSource(layer.source as string)) return;

    map.setFeatureState(
        {
            source: layer.source as string,
            ...(layer["source-layer"] ? { sourceLayer: layer["source-layer"] } : {}),
            id: featureId,
        },
        { hover: state },
    );
};

let prevHoveredLayer: TPrevLayer | null = null;

export const highlightZone = ({
    feature,
    mapInstance,
    featureIdProperty,
    setHoveredFeature,
}: THighlightZoneProps) => {
    if (feature) {
        const newHoveredZoneId = feature.properties?.[featureIdProperty];
        const prevHoveredZoneId = prevHoveredLayer?.[featureIdProperty] as string | number;

        if (String(prevHoveredZoneId) === String(newHoveredZoneId)) return;

        if (prevHoveredLayer) {
            toggleHoverState(mapInstance, prevHoveredLayer, prevHoveredZoneId, false);
        }

        toggleHoverState(mapInstance, feature.layer, newHoveredZoneId, true);
        setHoveredFeature?.(feature);

        prevHoveredLayer = { ...feature.layer, [featureIdProperty]: newHoveredZoneId };
    } else if (prevHoveredLayer) {
        toggleHoverState(
            mapInstance,
            prevHoveredLayer,
            prevHoveredLayer?.[featureIdProperty] as string | number,
            false,
        );
        setHoveredFeature?.(null);
        prevHoveredLayer = null;
    }
};

// If we use highlightZone we MUST be sure that all features have the same featureIdProperties.
// But there is a case when we hover one after another two zones with different featureIdProperties.
// For example, in VizTMC map there are features with "zone_id" and "arrow_id" properties.
// The problem is that when we switch hover from zone with "arrow_id" featureIdProperty to
// zone with "zone_id" featureIdProperty, 'prevHoveredLayer?.[featureIdProperty]' is undefined
// (as different featureIdProperty are used). As a result we will not able to reset hover state of
// previously hovered zone and two zones will be highlighted on map.
// createHighlightZone guarantees that hover state of previously hovered zone will be reset, as
// different _prevHoveredLayer are saved for layers with different featureIdProperties.
export const _createHighlightZone = () => {
    let _prevHoveredLayer: TPrevLayer | null = null;

    return ({
        feature,
        mapInstance,
        featureIdProperty,
        setHoveredFeature,
    }: THighlightZoneProps) => {
        if (feature) {
            const newHoveredZoneId = feature.properties?.[featureIdProperty];
            const prevHoveredZoneId = _prevHoveredLayer?.[featureIdProperty] as string | number;

            if (String(prevHoveredZoneId) === String(newHoveredZoneId)) return;

            if (_prevHoveredLayer) {
                toggleHoverState(mapInstance, _prevHoveredLayer, prevHoveredZoneId, false);
            }

            toggleHoverState(mapInstance, feature.layer, newHoveredZoneId, true);
            setHoveredFeature?.(feature);

            _prevHoveredLayer = { ...feature.layer, [featureIdProperty]: newHoveredZoneId };
        } else if (_prevHoveredLayer) {
            toggleHoverState(
                mapInstance,
                _prevHoveredLayer,
                _prevHoveredLayer?.[featureIdProperty] as string | number,
                false,
            );
            setHoveredFeature?.(null);

            _prevHoveredLayer = null;
        }
    };
};

let prevHoveredLayers: TPrevLayer[] = [];

export const highlightRoad = ({
    feature,
    mapInstance,
    featureIdProperty,
    layer,
}: THighlightZoneProps) => {
    if (!layer) return;

    if (feature) {
        if (
            prevHoveredLayers.length &&
            feature.properties?.road_name_highway === prevHoveredLayers[0].roadNameHighway
        ) {
            return;
        }

        if (prevHoveredLayers.length) {
            prevHoveredLayers.forEach(_prevHoveredLayer => {
                toggleHoverState(
                    mapInstance,
                    _prevHoveredLayer,
                    _prevHoveredLayer?.[featureIdProperty] as string | number,
                    false,
                );
            });

            prevHoveredLayers = [];
        }

        const roadFeatures = mapInstance.querySourceFeatures(layer.source as string, {
            sourceLayer: layer["source-layer"],
            filter: ["==", ["get", "road_name_highway"], feature.properties?.road_name_highway],
        });

        const uniqRoadFeatures = uniqBy(roadFeatures, "id");

        uniqRoadFeatures.forEach(_feature => {
            const hoveredZoneId = _feature.properties?.[featureIdProperty];
            toggleHoverState(mapInstance, layer, hoveredZoneId, true);

            prevHoveredLayers.push({
                ...layer,
                [featureIdProperty]: hoveredZoneId,
                roadNameHighway: _feature.properties?.road_name_highway,
            });
        });
    } else if (prevHoveredLayers.length) {
        prevHoveredLayers.forEach(_prevHoveredLayer => {
            toggleHoverState(
                mapInstance,
                _prevHoveredLayer,
                _prevHoveredLayer?.[featureIdProperty] as string | number,
                false,
            );
        });

        prevHoveredLayers = [];
    }
};

const rad2deg = (rad: number) => rad * 57.29577951308232;
const DEFAULT_PICKER_SIZE = 48 as const;

// https://github.com/mui-org/material-ui-pickers/blob/fd8b8322c8561b30c3ae6eb81881519ca5a7f0fb/lib/src/_helpers/time-utils.ts
// Convert mouse coordinates into degrees (angle)
export const getAngleValue = (
    step: number,
    offsetX: number,
    offsetY: number,
    pickerSize: number = DEFAULT_PICKER_SIZE,
) => {
    const PICKER_CENTER = {
        x: pickerSize / 2,
        y: pickerSize / 2,
    };
    const BASE_PICKER_POINT = {
        x: PICKER_CENTER.x,
        y: 0,
    };

    const cx = BASE_PICKER_POINT.x - PICKER_CENTER.x;
    const cy = BASE_PICKER_POINT.y - PICKER_CENTER.y;

    const x = offsetX - PICKER_CENTER.x;
    const y = offsetY - PICKER_CENTER.y;

    const atan = Math.atan2(cx, cy) - Math.atan2(x, y);

    let deg = rad2deg(atan);
    deg = Math.round(deg / step) * step;
    deg %= 360;

    const value = Math.floor(deg / step) || 0;
    const delta = x ** 2 + y ** 2;
    const distance = Math.sqrt(delta);

    return { value, distance };
};

export const toSourceId = (sourceId: string) => `stl:${sourceId}`;

export const calculateLineDirection = (start: Position, end: Position): number => {
    return Math.round((bearing(start, end) + 360) % 360);
};

export const withVerifySourceAndLayerPrefixes = (map: Map) => {
    const originalAddSource = map.addSource;
    const originalGetSource = map.getSource;
    const originalAddLayer = map.addLayer;
    const originalGetLayer = map.getLayer;

    map.addSource = (...args) => {
        const [sourceId] = args;

        if (!sourceId.startsWith("stl:") && !sourceId.startsWith("mapbox-gl-draw")) {
            console.error(
                `All source ids should start with 'stl:' prefix. Source with ${sourceId} id was created.`,
            );
        }

        try {
            originalAddSource.apply(map, args);
        } catch (error: any) {
            const message = error?.message ? error.message : error;
            throw new Error(`SourceId: ${sourceId}. ${message}`);
        }

        return map;
    };
    map.getSource = (...args) => {
        const [sourceId] = args;

        if (!sourceId.startsWith("stl:") && !sourceId.startsWith("mapbox-gl-draw")) {
            console.error(
                `Source lookup by ${sourceId} has been done. Most likely it misses 'stl:' prefix. `,
            );
        }

        return originalGetSource.apply(map, args);
    };
    map.addLayer = (...args) => {
        const [layer] = args;

        if (!layer.id.startsWith("stl:") && !layer.id.startsWith("gl-draw")) {
            console.error(
                `All layer ids should start with 'stl:' prefix. Layer with ${layer.id} id was created.`,
            );
        }

        return originalAddLayer.apply(map, args);
    };
    map.getLayer = (...args) => {
        const [layerId] = args;

        if (!layerId.startsWith("stl:") && !layerId.startsWith("gl-draw")) {
            console.error(
                `Layer lookup by ${layerId} has been done. Most likely it misses 'stl:' prefix. `,
            );
        }

        return originalGetLayer.apply(map, args);
    };

    return map;
};

export const convertGeoJsonToFeature = (geoJson: string, properties: GeoJsonProperties = {}) => {
    const geometry = JSON.parse(geoJson);

    return {
        type: "Feature",
        geometry: {
            type: geometry.type,
            coordinates: geometry.coordinates,
        },
        properties,
    };
};

export const convertLineGeoJsonToPoint = ({
    geoJson,
    isStart,
    properties,
}: {
    geoJson: string;
    isStart: boolean;
    properties: GeoJsonProperties;
}) => {
    const geometry = JSON.parse(geoJson);

    return {
        type: "Feature",
        geometry: {
            type: "Point",
            coordinates: isStart
                ? geometry.coordinates[0]
                : geometry.coordinates[geometry.coordinates.length - 1],
        },
        properties,
    };
};

export const removeHoverConditionFromColorExpression = (colorExpression: Expression) => {
    let hoverConditionIndex = -2;
    const hoverCondition = ["boolean", ["feature-state", "hover"], false];

    const reducedColorExpression = colorExpression.reduce((expression, item, index) => {
        if (Array.isArray(item) && isEqual(item, hoverCondition)) {
            hoverConditionIndex = index;
            return expression;
        }

        // Need to remove next condition after hover condition
        if (index === hoverConditionIndex + 1) {
            return expression;
        }

        return [...expression, item];
    }, []);

    if (reducedColorExpression.length === 2 && reducedColorExpression[0] === "case") {
        return reducedColorExpression[1];
    }

    return reducedColorExpression;
};

export const getBaseMapStyleProvider = (style: EMapStyleIds): TMapStyleProvider =>
    MAP_STYLE_PROVIDERS.mapbox.styles.includes(style) ? "mapbox" : "esri";

export const getBaseMapStyleUrl = (style: EMapStyleIds) =>
    getBaseMapStyleProvider(style) === "mapbox"
        ? `${MAP_STYLE_PROVIDERS.mapbox.baseUrl}${style}`
        : `${MAP_STYLE_PROVIDERS.esri.baseUrl}${style}?token=${ESRI_ACCESS_TOKEN}`;
