import type { Feature, LineString } from "geojson";
import type {
    IHoveredGate,
    IIntersection,
    IAvailableIntersection,
} from "@common/features/intersections/intersection.types";
import { MAP_MODES, TMapMode } from "./tmcChooseZones.constants";

export interface ITMCChooseZonesState {
    mapMode: TMapMode;
    hoveredGate: IHoveredGate | null;
    editableFeature: Feature<LineString> | null;
    availableIntersections: Array<IAvailableIntersection>;
    intersectionZones: Record<IIntersection["id"], IIntersection>; // -
}

export const TMC_CHOOSE_ZONES_INITIAL_STATE = {
    mapMode: MAP_MODES.VIEW_MAP,
    editableFeature: null,
    availableIntersections: [],
    intersectionZones: {},
    hoveredGate: null,
} as ITMCChooseZonesState;
