import { TIME_PERIODS_ACTION_TYPES } from "@app/analysis/timePeriods/state/timePeriods.actionTypes";
import { getDayTypeSettings } from "@app/analysis/timePeriods/state/timePeriods.selectors";
import { DAY_TYPES, TDayTypeKind } from "@app/analysis/timePeriods/state/timePeriods.constants";
import type { TAppDispatch } from "@app/store";
import type { TGetState } from "@app/store/root.reducer";
import type { IDayType } from "@app/analysis/timePeriods/timePeriods.types";

const validateDayItemName = (name: string) => !/[^a-zA-Z0-9 _-]+/g.test(name);

// Creates a shallow copy of an array of day items (day types or day parts) and trims their names.
const trimDayItemsName = (dayItems: Array<IDayType>) =>
    dayItems.map(dayItem => ({
        ...dayItem,
        name: dayItem.name.trim(),
    }));

/*
 * Checks an array of day types and returns an array of reasons if they are invalid.
 * Mutates each day type name to trim whitespace.
 * If the day type is valid, the reason will be null.
 */
export const validateDayTypes = (dayTypes: Array<IDayType>) => {
    const invalidReasons = [] as Array<string>;
    const names = new Set();
    const dayRanges = new Set();

    dayTypes.forEach(dayType => {
        let reason: string | null = null;
        if (!dayType.name) {
            reason = "Please provide a day type name";
        } else if (!validateDayItemName(dayType.name)) {
            reason =
                "Day type names must be alphanumeric with underscores, hyphens, and spaces allowed";
        } else if (!dayType.start || !dayType.end) {
            reason = "Please provide a day type start and end";
        } else if (names.has(dayType.name)) {
            reason = "Please provide unique day type names";
        } else {
            names.add(dayType.name);
            const range = `${dayType.start.name}-${dayType.end.name}`;
            if (dayRanges.has(range)) {
                reason = "Please provide unique day type start and end days";
            } else {
                dayRanges.add(range);
            }
        }

        invalidReasons.push(reason as string);
    });

    return invalidReasons;
};

export const addDayType = () => ({
    type: TIME_PERIODS_ACTION_TYPES.ADD_DAY_TYPE,
    data: { dayType: DAY_TYPES.NEW },
});

export const removeDayType = (index: number) => ({
    type: TIME_PERIODS_ACTION_TYPES.REMOVE_DAY_TYPE,
    data: { index },
});

export const setDayTypes =
    (dayTypes: Array<IDayType>) => (dispatch: TAppDispatch, getState: TGetState) => {
        const state = getState();

        const dayTypeSettings = getDayTypeSettings(state);

        const newDayTypes = trimDayItemsName(dayTypes);
        const reasons = validateDayTypes(newDayTypes);
        const updatedDayTypes = newDayTypes.map((dayType, i) => {
            const reason = reasons[i];
            if (reason !== null) {
                // There is invalid data, so use previous day type values.
                return {
                    ...dayTypeSettings.dayTypes[i],
                    editMode: true,
                    reason,
                    isInvalid: true,
                };
            } else {
                const validDayType = { ...dayType };

                delete validDayType.reason;
                delete validDayType.isInvalid;

                return validDayType;
            }
        });

        dispatch({
            type: TIME_PERIODS_ACTION_TYPES.SET_DAY_TYPES,
            data: { dayTypes: updatedDayTypes },
        });
    };

export const setDailyDayTypes = () => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DAILY_DAY_TYPES,
});

export const setInitialDayTypes = () => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_INITIAL_DAY_TYPES,
});

export const clearDayTypes = () => ({
    type: TIME_PERIODS_ACTION_TYPES.CLEAR_DAY_TYPES,
});

export const setDayTypeKind = (dayTypeKind: TDayTypeKind["id"]) => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DAY_TYPE_KIND,
    data: { dayTypeKind },
});

export const setDraftCustomDayTypes = (draftCustomDayTypes: Array<IDayType>) => ({
    type: TIME_PERIODS_ACTION_TYPES.SET_DRAFT_CUSTOM_DAY_TYPES,
    data: { draftCustomDayTypes },
});
