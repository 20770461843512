import { MAP_STYLES } from "@app/viz3/tmcVisualization/map/mapStyles.constants";
import { TMC_LAYERS } from "@app/viz3/tmcVisualization/state/tmcViz.constants";

export const TMC_VIZ_INITIAL_STATE = {
    map: {
        zoneSelection: null,
        clickedZone: null,
        layerConfigurations: {
            [TMC_LAYERS.GATE.code]: {
                colors: {
                    hover: MAP_STYLES.FILTER_ZONES.COLOR.HOVER,
                },
                opacity: MAP_STYLES.FILTER_ZONES.OPACITY,
            },
        },
        hoveredArrow: null,
    },
    zoneLayers: null,
    preSelectedZones: [],
    diagramData: {
        isLoading: false,
        isError: false,
        data: null,
    },
};
