import { createSelector } from "reselect";
import { capitalizeTheFirstLetter } from "@common/formatters/formatString";
import type { TRootState } from "@app/store";
import type {
    IIntersectionZone,
    IIntersectionZoneGate,
} from "@common/features/intersections/intersection.types";

/*
    Getter of the main object, just for the sake of convenience.
*/
export const getBaseTMCChooseZones = (state: TRootState) =>
    state.analysisConfiguration.tmcChooseZones;

export const getAvailableIntersections = (state: TRootState) =>
    getBaseTMCChooseZones(state).availableIntersections;

export const getIntersectionZones = (state: TRootState) =>
    getBaseTMCChooseZones(state).intersectionZones;

export const getEditableFeature = (state: TRootState) =>
    getBaseTMCChooseZones(state).editableFeature;

export const getHoveredGate = (state: TRootState) => getBaseTMCChooseZones(state).hoveredGate;

export const getIntersectionZonesList = createSelector(getIntersectionZones, intersectionZones =>
    Object.values(intersectionZones),
);

// Creates an array of selected intersections in specified by BE format.
export const getApiIntersectionZones = createSelector(
    getIntersectionZonesList,
    intersectionZones => {
        if (!intersectionZones) return [];

        return intersectionZones.reduce((result, zone) => {
            if (zone.isImported) {
                return [...result, { zone_id: zone.zone_id, gates: zone.gates }];
            }

            const gates = zone.gates.map(gate => ({
                line_geom: gate.line_geom,
                role: gate.role,
                direction: gate.direction ? capitalizeTheFirstLetter(gate.direction) : undefined,
                road: gate.road,
            })) as Array<IIntersectionZoneGate>;

            return [...result, { name: zone.zone_name, gates }];
        }, [] as Array<Partial<IIntersectionZone>>);
    },
);

export const getSelectedIntersection = createSelector(
    getIntersectionZonesList,
    intersectionZones => {
        if (!intersectionZones.length) return null;

        return intersectionZones[0];
    },
);

export const getSelectedGate = createSelector(
    getSelectedIntersection,
    selectedIntersection => selectedIntersection?.selectedGate,
);

export const getAllSelectedIntersectionZoneIds = createSelector(
    getIntersectionZonesList,
    intersectionZones =>
        intersectionZones.reduce((zoneIds, { zone_id }) => {
            if (zone_id) zoneIds.push(String(zone_id));

            return zoneIds;
        }, [] as Array<string>),
);
