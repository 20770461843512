import { lazy } from "react";
import { StlLoader } from "@common/components";
import { StlStatus404Page, StlStatus403Page } from "./statuses";
import { EXIT_SANDBOX_PATH } from "./auth.constants";

const LoginPage = lazy(() => import("./login"));
const LogoutPage = lazy(() => import("./logout"));
const ChooseOrgPage = lazy(() => import("./chooseOrg"));
const ChooseStudyPage = lazy(() => import("./chooseStudy"));
const VerifySignaturePage = lazy(() => import("./verifySignature"));
const PasswordResetPage = lazy(() => import("./passwordReset"));
const ZendeskSSO = lazy(() => import("./zendeskSSO"));
const PasswordResetSubmitPage = lazy(() => import("./passwordResetSubmit"));
const StlSandbox = lazy(() => import("./sandbox"));

export const routes = [
    {
        path: "/server",
        component: () => <StlLoader show />,
        id: "stlServerRedirect",
        title: "Redirect",
    },
    {
        path: "/login",
        component: LoginPage,
        id: "login",
        title: "Login",
    },
    {
        path: "/logout",
        component: LogoutPage,
        id: "logout",
        title: "Sign Out",
    },
    {
        path: "/chooseOrg",
        component: ChooseOrgPage,
        id: "chooseOrg",
        title: "Choose Account",
    },
    {
        path: "/chooseStudy",
        component: ChooseStudyPage,
        id: "chooseStudy",
        title: "Choose Study",
    },
    {
        path: "/verifySignature",
        component: VerifySignaturePage,
        id: "verifySignature",
        title: "Verify Signature",
    },
    {
        path: "/password/reset/:token",
        component: PasswordResetSubmitPage,
        id: "passwordResetSubmit",
        title: "Password Reset Submit",
    },
    {
        path: "/password/reset",
        component: PasswordResetPage,
        id: "passwordReset",
        title: "Password Reset",
    },
    {
        path: "/zendeskSSO",
        component: ZendeskSSO,
        id: "zendeskSSO",
        title: "Zendesk SSO",
    },
    {
        path: "/status404",
        component: StlStatus404Page,
        id: "status404",
        title: "Page Not Found",
    },
    {
        path: "/status403",
        component: StlStatus403Page,
        id: "status403",
        title: "Access Denied",
    },
    {
        path: "/sandbox",
        component: StlSandbox,
        id: "sandbox",
    },
    {
        path: EXIT_SANDBOX_PATH,
        component: StlSandbox,
        id: "sandbox",
    },
];
