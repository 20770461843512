import moment from "moment";
import { isAADTAnalysisType } from "@app/analysis/state/analysisConfiguration.helpers";
import type { IGetAnalysesTypeKeywordsResponse } from "@common/services/server/analysesApi.types";
import {
    TAnalysesFilterData,
    IKeywordsObject,
    IKeywordsByAnalysisId,
    IOSMVintageDate,
} from "@app/store/staticData/state/staticData.types";
import {
    CREATE_ANALYSIS_TYPES,
    CREATE_ANALYSIS_TYPES_LIST,
    TTravelMode,
} from "@app/analysis/state/analysisConfiguration.constants";
import { getIsOrgHasFeature } from "@app/store/currentUser/currentUser.selector";
import { TRootState } from "@app/store";
import { ANALYSIS_TYPE_KEYWORD_SEPARATOR } from "./staticData.constants";

export const getOSMLayerLabel = (label: string, osmVintageDate?: IOSMVintageDate) => {
    if (!osmVintageDate?.year_num || !osmVintageDate?.month_num) {
        return label;
    }
    const date = moment([osmVintageDate.year_num, osmVintageDate.month_num - 1]);

    return `${label} (${date.format("MMM, YYYY")})`;
};

export const splitAnalysisTypeKeywords = (
    analysisTypeKeywords: IGetAnalysesTypeKeywordsResponse["data"]["keywords"],
) => {
    return Object.entries(analysisTypeKeywords).reduce(
        (acc, [key, value]) => ({
            ...acc,
            [key]: value.split(ANALYSIS_TYPE_KEYWORD_SEPARATOR).filter(Boolean),
        }),
        {} as IKeywordsObject,
    );
};

export const keywordsToAnalysisId = (keywordsObject: IKeywordsObject) => {
    return Object.keys(keywordsObject).reduce((res, analysisTypeCode) => {
        const isAADTAnalysis = isAADTAnalysisType(analysisTypeCode);
        const _analysisTypeCode = isAADTAnalysis
            ? CREATE_ANALYSIS_TYPES.AADT.code
            : analysisTypeCode;

        keywordsObject[analysisTypeCode].forEach(keyword => {
            if (!res[keyword]) {
                res[keyword] = [];
            }

            if (!res[keyword].includes(_analysisTypeCode)) {
                res[keyword].push(_analysisTypeCode);
            }
        });
        return res;
    }, {} as IKeywordsByAnalysisId);
};

export const getIsAnalysisTypeAvailable = (analysisCode: string, state: TRootState) => {
    if (analysisCode.toLowerCase() === "aadt") {
        return Object.values(CREATE_ANALYSIS_TYPES.AADT.aadtTypes).some(aadtType =>
            getIsOrgHasFeature(state, aadtType.featureName),
        );
    }

    const analysisFromCode = CREATE_ANALYSIS_TYPES_LIST.find(
        analysis => analysis.code.toLowerCase() === analysisCode.toLowerCase(),
    ) as { featureName: string } | undefined;

    return analysisFromCode && getIsOrgHasFeature(state, analysisFromCode.featureName);
};

export const extractAnalysesMetadataAndFeatureCodeNameRelation = (
    input: IGetAnalysesTypeKeywordsResponse["data"]["mode_of_travel"],
    state: TRootState,
) => {
    const modeOfTravelAnalysesData: TAnalysesFilterData = {};

    for (const [mode, { feature_code_name, analysis_types }] of Object.entries(input)) {
        if (!getIsOrgHasFeature(state, feature_code_name)) break;

        const analysisTypes: TAnalysesFilterData[TTravelMode["code"]] = {};

        Object.entries(analysis_types).forEach(([analysisCode, analysisFiltersMetadata]) => {
            if (getIsAnalysisTypeAvailable(analysisCode, state)) {
                analysisTypes[analysisCode] = analysisFiltersMetadata;
            }
        });

        modeOfTravelAnalysesData[mode as TTravelMode["code"]] = {
            ...analysisTypes,
        };
    }

    return { modeOfTravelAnalysesData };
};
