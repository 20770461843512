import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { IIntersection } from "@common/features/intersections/intersection.types";
import { ITMCChooseZonesState, TMC_CHOOSE_ZONES_INITIAL_STATE } from "./tmcChooseZones.state";

export const { actions, reducer } = createSlice({
    name: "tmcChooseZones",
    initialState: TMC_CHOOSE_ZONES_INITIAL_STATE,
    reducers: {
        setTMCChooseZonesState: (state, action: PayloadAction<ITMCChooseZonesState>) => {
            return action.payload;
        },
        resetTMCChooseZonesState: () => {
            return TMC_CHOOSE_ZONES_INITIAL_STATE;
        },
        updateAvailableIntersections: (
            state,
            action: PayloadAction<ITMCChooseZonesState["availableIntersections"]>,
        ) => {
            state.availableIntersections = action.payload;
        },
        setIntersectionZones: (
            state,
            action: PayloadAction<ITMCChooseZonesState["intersectionZones"]>,
        ) => {
            state.intersectionZones = action.payload;
        },
        updateIntersectionZone: (state, action: PayloadAction<IIntersection>) => {
            state.intersectionZones[action.payload.id] = action.payload;
        },
        setEditableFeature: (
            state,
            action: PayloadAction<ITMCChooseZonesState["editableFeature"]>,
        ) => {
            state.editableFeature = action.payload;
        },
        setMapMode: (state, action: PayloadAction<ITMCChooseZonesState["mapMode"]>) => {
            state.mapMode = action.payload;
        },
        setHoveredGate: (state, action: PayloadAction<ITMCChooseZonesState["hoveredGate"]>) => {
            state.hoveredGate = action.payload;
        },
    },
});

export const tmcChooseZonesReducer = reducer;
